export class AppConstants {
  public static readonly ACCESS_TOKEN = 'userToken'
  public static readonly API_URL = process.env.REACT_APP_API_BASE_URL || '/api'
  public static readonly CURRENTROLE_TOKEN = 'userCurrentRole'
  public static readonly USER_FIRST_NAME = 'user_first_name'
}

export enum Action_Type {
  SELECTED_PLAN_INFO = 'Selected_Plan_Info',
  SELECTED_SLOT = 'Selected_Slot',
  SELECTED_PLAN = 'Selected_Plan',
  AVAILABLE_PLAN = 'Available_Plan',
  SLOT_BOOKED_ALREADY = 'Slot_Booked_Already',
  SLOT_NOT_FOUND = 'Slot_Not_Found',
  PAYMENT_DONE = 'Payment_Done',
  STUDIOS = 'Studios',
  STUDIO = 'Studio',
  IS_MORE_THAN_ONE_STUDIO = 'Is_More_Than_One_Studio',
}

export enum Flow_Type {
  OFFERS = 'offers',
  REFERRAL = 'referral',
}

export enum Offers_Flow_Action_Type {
  VISIT_COUNT_OFFERS_FLOW = 'Visit_Count_Offers_Flow',
  SELECTED_SLOT_OFFERS_FLOW = 'Selected_Slot_Offers_Flow',
  SLOT_BOOKED_ALREADY_OFFERS_FLOW = 'Slot_Booked_Already_Offers_Flow',
  SLOT_NOT_FOUND_OFFERS_FLOW = 'Slot_Not_Found_Offers_Flow',
  PAYMENT_PLAN_OFFERS_FLOW = 'Payment_Plan_Offers_Flow',
  PURCHASED_PLAN_OFFERS_FLOW = 'Purchased_Plan_Offers_Flow',
  PAYMENT_DONE_OFFERS_FLOW = 'Payment_Done_Offers_Flow',
  TIME_LEFT_OFFERS_FLOW = 'Time_Left_Offers_Flow',
  TIME_LEFT_HOME_OFFERS_FLOW = 'Time_Left_Home_Offers_Flow',
  STUDIOS_OFFERS_FLOW = 'Studios_Offers_Flow',
  STUDIO_OFFERS_FLOW = 'Studio_Offers_Flow',
  IS_MORE_THAN_ONE_STUDIO_OFFERS = 'Is_More_Than_One_Studio_Offers',
}

export enum Channel {
  direct = 'direct',
  marketing = 'marketing',
  referral = 'referral',
  free = 'free',
}

export enum LeadStage {
  home = 'home',
  name = 'name',
  phone = 'phone',
  otp = 'otp',
  location = 'location',
  slot = 'slot',
  plan = 'plan',
  confirmation = 'confirmation',
  payment = 'payment',
  done = 'done',
}
