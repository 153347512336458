import { createSlice } from '@reduxjs/toolkit'
import { IPlan } from 'redux/booking'
import { studio } from 'redux/persist'

export interface ITime {
  index: number
  value: string
}

interface IScheduleInitialValues {
  scheduleObject: {
    appointmentTime: string
    timezone: string
    clinicId: string | undefined | null
  }
  index?: number | null
  value?: ITime | null
  date?: any
}

interface PurchasedPlan {
  clientSecret: string
  paymentId: string
  paymentIntentId: string
}

// // Slot booked already Persist

// interface SlotBookedError {
//   slotBookedAlreadyReferralFlow: boolean
// }
// const slotBookedAlreadyReferralFlowState: SlotBookedError = {
//   slotBookedAlreadyReferralFlow: false,
// }

// export const SetSlotBookedAlreadyReferralFlow = (slotBookedAlreadyReferralFlow: boolean) => ({
//   type: Referral_Flow_Action_Type.SLOT_BOOKED_ALREADY_REFERRAL_FLOW,
//   payload: slotBookedAlreadyReferralFlow,
// })

export interface ReferralPersistState {
  paymentPlanReferral: Array<IPlan>
  purchasedPlanReferral: PurchasedPlan | null
  selectedSlotReferralFlow: IScheduleInitialValues | null
  studiosReferral: Array<studio>
  studioReferral: studio | null
  slotBookedAlreadyReferral: boolean
  slotNotFoundReferral: boolean
  isPaymentDoneReferral: boolean
  isMoreThanOneStudioReferral: boolean
  timeLeft: number | null
  timeLeftHome: number | null
}

const initialState: ReferralPersistState = {
  paymentPlanReferral: [],
  purchasedPlanReferral: null,
  selectedSlotReferralFlow: null,
  studiosReferral: [],
  studioReferral: null,
  slotBookedAlreadyReferral: false,
  slotNotFoundReferral: false,
  isPaymentDoneReferral: false,
  isMoreThanOneStudioReferral: false,
  timeLeft: null,
  timeLeftHome: null,
}

export const referralPersistSlice = createSlice({
  name: 'referralPersist',
  initialState,
  reducers: {
    SetPaymentPlanReferral: (state, action) => {
      state.paymentPlanReferral = action.payload
    },
    SetPurchasedPlanReferral: (state, action) => {
      state.purchasedPlanReferral = action.payload
    },
    SetSelectedSlotReferralFlow: (state, action) => {
      state.selectedSlotReferralFlow = action.payload
    },
    SetStudiosReferral: (state, action) => {
      state.studiosReferral = action.payload
    },
    SetSelectedStudioReferral: (state, action) => {
      state.studioReferral = action.payload
    },
    SetSlotBookedAlreadyReferral: (state, action) => {
      state.slotBookedAlreadyReferral = action.payload
    },
    SetSlotNotFoundReferral: (state, action) => {
      state.slotNotFoundReferral = action.payload
    },
    SetIsPaymentDoneReferral: (state, action) => {
      state.isPaymentDoneReferral = action.payload
    },
    SetIsMoreThanOneStudioReferral: (state, action) => {
      state.isMoreThanOneStudioReferral = action.payload
    },
    SetTimeLeftReferral: (state, action) => {
      state.timeLeft = action.payload
    },
    SetTimeLeftHomeReferral: (state, action) => {
      state.timeLeftHome = action.payload
    },
  },
})

export default referralPersistSlice.reducer

export const {
  SetSelectedSlotReferralFlow,
  SetStudiosReferral,
  SetSelectedStudioReferral,
  SetIsMoreThanOneStudioReferral,
  SetPaymentPlanReferral,
  SetPurchasedPlanReferral,
  SetSlotBookedAlreadyReferral,
  SetSlotNotFoundReferral,
  SetIsPaymentDoneReferral,
  SetTimeLeftReferral,
  SetTimeLeftHomeReferral,
} = referralPersistSlice.actions
