import React, { useEffect, useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
// import { analytics } from 'index'
import axios from 'axios'
import ReactGA from 'react-ga4'
import { useParams } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import styles from './Download.module.scss'

enum Device_Type {
  ios = 'IOS',
  android = 'Android',
  desktop = 'Desktop',
}
const DownloadBooking = () => {
  const { id } = useParams()
  const [device, setDevice] = useState('')
  const [platform, setPlatform] = useState('')
  const [isRedirected, setIsRedirected] = useState(false)

  useEffect(() => {
    const userAgent = navigator.userAgent
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent)
    const isWindows = navigator.platform.startsWith('Win')
    const isMac = navigator.platform.startsWith('Mac')
    if (isWindows) {
      setPlatform('Win')
    } else if (isMac) {
      setPlatform('Mac')
    }
    if (isMobile) {
      if (/iPad/i.test(userAgent)) {
        setDevice(Device_Type.ios)
        toAppStore()
      } else if (/iPhone/i.test(userAgent)) {
        setDevice(Device_Type.ios)
        toAppStore()
      } else if (/Android/i.test(userAgent)) {
        setDevice(Device_Type.android)
        toPlayStore()
      }
    } else {
      setDevice(Device_Type.desktop)
    }

    return () => {}
  }, [])

  // useEffect(() => {
  //   analytics.page()
  // }, [])

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Download',
      page_location: `/booking/${id}/download`,
    })
  }, [])

  const toAppStore = () => {
    // window.location.href = `https://apps.apple.com/us/app/kiro-chiropractic-adjustments/id6475482372`
    window.open('https://apps.apple.com/us/app/kiro-chiropractic-adjustments/id6475482372', '_blank')
    setTimeout(() => {
      setIsRedirected(true)
    }, 1000)
  }

  const toPlayStore = () => {
    // window.location.href = `https://play.google.com/store/apps/details?id=com.getkiro.kiro`
    window.open('https://play.google.com/store/apps/details?id=com.getkiro.kiro', '_blank')
    setTimeout(() => {
      setIsRedirected(true)
    }, 1000)
  }

  if (device === Device_Type.desktop) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerText}>
            <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', left: '21px', top: '20px' }}>
              <span>{`Download KIRO`}</span>
            </div>
          </div>
          <img src={'/logo2.png'} alt="logo" className={styles.logo} />
        </div>
        <div className={styles.userDetails}>
          <div className={styles.bodyContainer}>
            <div className={styles.downloadTextWrapper}>
              <div className={styles.downloadText}>
                The KIRO mobile app is required to schedule and manage appointments, check in at the studio, and access
                your account details. Available on iOS and Android.
              </div>
            </div>
            <div>
              {device === Device_Type.desktop && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    gap: '18px',
                    flexDirection: 'row',
                    // marginLeft: '8px',
                  }}
                >
                  <Button
                    className={styles.appStoreButton}
                    sx={{ width: '147px', height: '42px', bordeRadius: ' 6px' }}
                    onClick={toAppStore}
                  >
                    <img src={'/images/app-store.svg'} style={{ marginRight: '5px' }}></img>
                    App Store
                  </Button>

                  <Button
                    className={styles.appStoreButton}
                    sx={{ width: '147px', height: '42px', bordeRadius: '6px' }}
                    onClick={toPlayStore}
                  >
                    <img src={'/images/play-store.svg'} style={{ marginRight: '8px' }}></img>
                    Google Play
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer
          buttonText={'Download KIRO'}
          isLoading={false}
          trackerIndex={10}
          isDisabled={false}
          buttonType={'submit'}
          onButtonClick={() => {
            if (platform === 'Win') {
              toPlayStore()
            } else if (platform === 'Mac') {
              toAppStore()
            } else {
              toAppStore()
            }
          }}
        ></Footer>
      </div>
    )
  } else {
    return (
      <div className={styles.container}>
        {!isRedirected && (
          <div className={styles.circularProgress}>
            <CircularProgress
              size={40}
              sx={{
                color: '#007F7C',
                width: '14px',
                height: '14px',
              }}
            />
          </div>
        )}
        {isRedirected && (
          <div>
            <div className={styles.header}>
              <div className={styles.headerText}>
                <div
                  style={{ display: 'flex', flexDirection: 'column', position: 'absolute', left: '16px', top: '16px' }}
                >
                  <span>{`Download KIRO`}</span>
                </div>
              </div>
              <img src={'/logo2.png'} alt="logo" className={styles.logo} />
            </div>
            <div className={styles.userDetails}>
              <div className={styles.bodyContainer}>
                <div className={styles.downloadTextWrapper}>
                  <div className={styles.downloadText}>
                    The KIRO mobile app is required to schedule and manage appointments, check in at the studio, and
                    access your account details. Available on iOS and Android.
                  </div>
                </div>
                <div>
                  <div>
                    {device === Device_Type.android && (
                      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        <Button
                          className={styles.appStoreButton}
                          sx={{ width: '50%', height: '42px', bordeRadius: '6px' }}
                          onClick={toPlayStore}
                        >
                          <img src={'/images/play-store.svg'} style={{ marginRight: '8px' }}></img>
                          Google Play
                        </Button>
                      </div>
                    )}
                    {device === Device_Type.ios && (
                      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        <Button
                          className={styles.appStoreButton}
                          sx={{ width: '50%', height: '42px', bordeRadius: ' 6px' }}
                          onClick={toAppStore}
                        >
                          <img src={'/images/app-store.svg'} style={{ marginRight: '5px' }}></img>
                          App Store
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Footer
              buttonText={'Download KIRO'}
              isLoading={false}
              trackerIndex={10}
              isDisabled={false}
              buttonType={'submit'}
              onButtonClick={() => {
                if (device === Device_Type.android) {
                  toPlayStore()
                } else if (device === Device_Type.ios) {
                  toAppStore()
                }
              }}
            ></Footer>
          </div>
        )}
      </div>
    )
  }
}

export default DownloadBooking
