import { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import axios from 'axios'
import ReactGA from 'react-ga4'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { SetIsMoreThanOneStudioOffers, SetTimeLeftHomeOfferFlow } from 'redux/Offers/persist'
import { useAppDispatch, useAppSelector } from 'redux/store'
import styles from './HomeOffers.module.scss'

const HomeOffers = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [amount, setAmount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const timeLeft = useAppSelector((state) => state.timeLeftHomeOfferFlow.timeLeftHomeOffers)

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  async function getPlans() {
    try {
      const response: any = await axios.get(`${AppConstants.API_URL}/payment-plan/public?campaign=${id}`, {
        headers: {
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })

      console.log(response, 'res res')
      if (response && response?.data && response?.data?.data) {
        setIsLoading(false)
        setAmount(response.data.data[0]?.amount)
      } else if (response?.data?.statusCode === 401) {
        setIsLoading(false)
        console.log('error')
      }
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  async function getStudioCount() {
    try {
      const response: any = await axios.get(`${AppConstants.API_URL}/operator/clinics/public`, {
        headers: {
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })

      if (response && response?.data && response?.data?.data) {
        if (response.data.data > 1) {
          dispatch(SetIsMoreThanOneStudioOffers(true))
        } else {
          dispatch(SetIsMoreThanOneStudioOffers(false))
        }
      } else if (response?.data?.statusCode === 401) {
        console.log('error')
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    dispatch(SetTimeLeftHomeOfferFlow(timeLeft ?? 299))
  }, [])

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        dispatch(SetTimeLeftHomeOfferFlow(timeLeft && timeLeft - 1))
      }, 1000)

      return () => clearInterval(timerId)
    }
  }, [timeLeft])

  useEffect(() => {
    getPlans()
    getStudioCount()
    ReactGA.event('page_view', {
      page_title: 'Offers|Home',
      page_location: `/offers/${id}`,
    })
  }, [])

  useEffect(() => {
    localStorage.setItem(AppConstants.USER_FIRST_NAME, '')
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerText}>
          <span>{'BOOK YOUR FIRST '}</span>
          <span>{`ADJUSTMENT AT KIRO.`}</span>
        </div>
        <img src={'/logo2.png'} alt="logo" className={styles.logo} />
      </div>

      <div className={styles.content}>
        {isLoading && (
          <div className={styles.circularProgress}>
            <CircularProgress
              size={40}
              sx={{
                color: '#007F7C',
                width: '14px',
                height: '14px',
              }}
            />
          </div>
        )}

        {!isLoading && (
          <div className={styles.textContainer}>
            <div className={styles.messageText}>
              <span>Join thousands of New Yorkers living</span>
              <br></br>
              <span>pain-free with care from NYC's #1</span>
              <br></br>
              <span>chiropractic studio.</span>
            </div>
            <div className={styles.offerDetails}>
              <div>
                <img src="/images/tickV3.svg"></img>
                <span style={{ marginLeft: '7px' }}>Includes: exam + total spinal adjustment</span>
              </div>
              <div>
                <img src="/images/tickV3.svg"></img>
                <span style={{ marginLeft: '7px' }}>
                  {amount === 0 ? 'Free! (normally $100)' : `$${amount / 100} Special (normally $100)`}
                </span>
              </div>
              <div>
                <img src="/images/tickV3.svg"></img>
                <span style={{ marginLeft: '7px' }}>500+ 5-Star Google reviews</span>
              </div>
              <div>
                <img src="/images/tickV3.svg"></img>
                <span style={{ marginLeft: '7px' }}>Book in under 1 minute</span>
              </div>
              <div>
                <img src="/images/tickV3.svg"></img>
                <span style={{ marginLeft: '7px' }}>Limited-time offer</span>
              </div>
            </div>
          </div>
        )}
        <div className={styles.offerExpires}>
          Offer Expires In: <span className={styles.timer}>{formatTime(timeLeft || 0)}</span>
        </div>
      </div>

      <Footer
        buttonText={`Claim Your Special Offer`}
        isLoading={false}
        trackerIndex={10}
        isDisabled={false}
        buttonType={'button'}
        onButtonClick={() => {
          navigate(`/offers/${id}/name`)
        }}
      ></Footer>
    </div>
  )
}

export default HomeOffers
