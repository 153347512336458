import { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import axios from 'axios'
import ReactGA from 'react-ga4'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { SetIsMoreThanOneStudio } from 'redux/persist'
import { useAppDispatch } from 'redux/store'
import styles from './Home.module.scss'

const HomeRegular = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(true)

  async function getStudioCount() {
    try {
      const response: any = await axios.get(`${AppConstants.API_URL}/operator/clinics/public`, {
        headers: {
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })

      if (response && response?.data && response?.data?.data) {
        setIsLoading(false)
        if (response.data.data > 1) {
          dispatch(SetIsMoreThanOneStudio(true))
        } else {
          dispatch(SetIsMoreThanOneStudio(false))
        }
      } else if (response?.data?.statusCode === 401) {
        setIsLoading(false)
        console.log('error')
      }
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  useEffect(() => {
    getStudioCount()
    ReactGA.event('page_view', {
      page_title: 'Regular|Home',
      page_location: `/`,
    })
  }, [])

  useEffect(() => {
    localStorage.setItem(AppConstants.USER_FIRST_NAME, '')
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerText}>
          <span>{'BOOK YOUR FIRST '}</span>
          <span>{`ADJUSTMENT AT KIRO.`}</span>
        </div>
        <img src={'/logo2.png'} alt="logo" className={styles.logo} />
      </div>

      <div className={styles.content}>
        {isLoading && (
          <div className={styles.circularProgress}>
            <CircularProgress
              size={40}
              sx={{
                color: '#007F7C',
                width: '14px',
                height: '14px',
              }}
            />
          </div>
        )}

        {!isLoading && (
          <div className={styles.textContainer}>
            <div className={styles.messageText}>
              <span>Join thousands of New Yorkers living</span>
              <br></br>
              <span>pain-free with care from NYC's #1</span>
              <br></br>
              <span>chiropractic studio.</span>
            </div>
            <div className={styles.offerDetails}>
              <div>
                <img src="/images/tickV3.svg"></img>
                <span style={{ marginLeft: '7px' }}>Includes: exam + total spinal adjustment</span>
              </div>
              <div>
                <img src="/images/tickV3.svg"></img>
                <span style={{ marginLeft: '7px' }}>500+ 5-Star Google reviews</span>
              </div>
              <div>
                <img src="/images/tickV3.svg"></img>
                <span style={{ marginLeft: '7px' }}>Book in under 1 minute</span>
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer
        buttonText={`Book Your Adjustment`}
        isLoading={false}
        trackerIndex={10}
        isDisabled={false}
        buttonType={'button'}
        onButtonClick={() => {
          navigate(`/name`)
        }}
      ></Footer>
    </div>
  )
}

export default HomeRegular
