import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button, TextField, Checkbox, CircularProgress } from '@mui/material'
import axios from 'axios'
import ReactGA from 'react-ga4'
import PhoneInput from 'react-phone-input-2'
import { useLocation, useNavigate } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import { AppConstants, Channel, LeadStage } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
// import TextField from 'components/shared/textField'
// import { loginVerifyCodeAsync, signInAsync } from 'redux/auth'
// import { useAppDispatch } from 'redux/store'
// import { analytics } from 'index'
import {
  SetAvailablePlans,
  SetIsMoreThanOneStudio,
  SetPaymentDone,
  SetSelectedPlan,
  SetSelectedPlanInfo,
  SetSelectedSlot,
  SetSelectedStudio,
  SetSlotBookedAlready,
  SetSlotNotFound,
  SetStudios,
} from 'redux/persist'
import { SetRegularFirstname, SetSkipCongrats } from 'redux/Regular'
import store, { useAppDispatch, useAppSelector } from 'redux/store'
import { StatusEnum } from 'redux/types'
import { decodeToken } from 'utils/decodeToken'
import { formatPhoneNumber } from 'utils/formatPhoneNumber'
import styles from './Signup.module.scss'
import style from './VerifyOtp.module.scss'
import useAuth from '../../../hooks/useAuth'

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [formattedPhoneNum, setFormattedPhoneNum] = useState('')
  // const [firstName, setFirstName] = useState('')
  const [errorType, setErrorType] = useState('')
  const [otpError, setOtpError] = useState(false)
  // const [firstNameError, setFirstNameError] = useState(false)
  const [OTPScreen, setOTPScreen] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const [isChecked, setIsChecked] = useState(true)
  const [disableVerifyBtn, setDisableVerifyBtn] = useState(true)
  const [code, setCode] = useState('')
  const [verifyStatus, setVerifyStatus] = useState<StatusEnum>(StatusEnum.Idle)
  const dispatch = useAppDispatch()
  const persistor = persistStore(store)
  const navigate = useNavigate()
  const [isCallInProgress, setIsCallInProgress] = useState(false)
  const isMoreThanOneStudio = useAppSelector((state) => state.isMoreThanOneStudio.isMoreThanOneStudio)
  const firstname = localStorage.getItem(AppConstants.USER_FIRST_NAME)
  // const [phoneInfo, setPhoneInfo]: any = useState({
  //   countryCode: '',
  //   dialCode: '',
  //   phoneLength: 10,
  // })

  useEffect(() => {
    if (verifyStatus === StatusEnum.Success) {
      let localToken = localStorage.getItem(AppConstants.ACCESS_TOKEN)
      if (localToken) {
        const isPatient = decodeToken(localToken)
        if (isPatient && isPatient === 'PATIENT') {
          getProfile()
        }
      }
      // if (currentRole) {
      //   if (currentRole === 'PATIENT') {
      //     navigate('/add-photo')
      //   } else {
      //     navigate('/signup')
      //   }
      // }
    }
  }, [verifyStatus])

  // useEffect(() => {
  //   analytics.page()
  // }, [])

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'login',
      page_location: '/login',
    })
  }, [])

  const handleCheckBoxChange = (e: any) => {
    setIsChecked(e.target.checked)
    if (e.target.checked === true && phoneNumber.length > 4) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }

  const handleKeyDown = (e: any, nextFieldName: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const nextField = e.target.form.elements[nextFieldName]
      if (nextField) {
        nextField.focus()
      }
    }
  }

  const sendOtp = async () => {
    if (phoneNumber.length >= 4) {
      setDisableButton(true)
      const response: any = await axios
        .post(AppConstants.API_URL + '/auth/patient/login', {
          firstname: firstname,
          phone: '+' + phoneNumber.replace(/-/g, ''),
        })
        .then((res) => res)
        .catch((err) => err.response)
      if (
        response &&
        response?.data?.code === 'success' &&
        response?.data?.message === 'Verification code sent successfully'
      ) {
        setDisableButton(false)
        setOTPScreen(true)
      } else if (response && response?.data?.message === 'Phone number is not found') {
        setErrorType('Not_User')
        setDisableButton(false)
      } else if (response && response?.data.message === 'Unable to send verification code') {
        setErrorType('formatInvalid')
        setDisableButton(false)
      } else if (response && response.data && response.data.code >= 400 && response.data.code <= 599) {
        setErrorType('Error_Occurred')
        setDisableButton(false)
      } else if (response && response.data && response.data.statusCode >= 400 && response.data.statusCode <= 599) {
        setErrorType('Error_Occurred')
        setDisableButton(false)
      }
    } else {
      if (firstname?.length === 0) {
        setErrorType('firstNameEmpty')
        setDisableButton(true)
      }
      if (phoneNumber.length < 4) {
        setErrorType('formatInvalid')
      }
      // setDisableButton(true)
    }
  }

  const onVerify = async () => {
    setDisableButton(true)
    setDisableVerifyBtn(true)

    if (code === '') {
      setOtpError(true)
      setErrorType('Empty')
      setDisableButton(false)
    } else {
      try {
        // console.log('payload', {
        //   phone: '+' + phoneNumber.replace(/-/g, ''),
        //   code: code,
        //   signupThrough: 'web',
        //   firstname: firstName,
        // })
        const response: any = await axios
          .post(AppConstants.API_URL + '/auth/patient/register/login/verify', {
            phone: '+' + phoneNumber.replace(/-/g, ''),
            code: code,
            signupThrough: 'web',
            firstname: firstname,
          })
          .then((res) => res)
          .catch((err) => err.response)
        console.log(response, 'total response')

        if (response && response.data && response.data.code === 'success') {
          ReactGA.event('login', {
            method: 'web',
          })
          localStorage.setItem(AppConstants.ACCESS_TOKEN, response?.data?.data?.accessToken)
          let localToken = response.data?.data?.accessToken
          let tokenParts = localToken.split('.')

          let tokenPayload = tokenParts[1]
          let tokenPayloadStr = atob(tokenPayload)

          let decodedToken: any = JSON.parse(tokenPayloadStr)

          let tempCurrentRole: any = ''
          if (decodedToken) {
            if (decodedToken.roles && decodedToken.roles.length === 1) {
              tempCurrentRole = decodedToken.roles[0]
            }
          } else {
            tempCurrentRole = null
          }
          localStorage.setItem(AppConstants.CURRENTROLE_TOKEN, tempCurrentRole)
          // if (tempCurrentRole === 'PATIENT') {
          //   navigate('/add-photo')
          // } else {
          //   navigate('/signup')
          // }
          setDisableVerifyBtn(false)
          setVerifyStatus(StatusEnum.Success)
        } else if (response && response.data && response.data.code === 'invalid_value') {
          setOtpError(true)
          setErrorType('Invalid')
          setDisableVerifyBtn(false)
          //   setVerifyStatus(StatusEnum.Failed)
        } else if (response && response.data && response.data.code === 'code_expired') {
          setOtpError(true)
          setErrorType('Code_Expired')
          setDisableVerifyBtn(false)
          //   setVerifyStatus(StatusEnum.Failed)
        } else if (response && response.data && response.data.code === 'user_not_found') {
          setErrorType('Not_User')
          setDisableVerifyBtn(false)
          //   setVerifyStatus(StatusEnum.Failed)
        } else if (response && response.data && response.data.statusCode >= 400 && response.data.statusCode <= 599) {
          setErrorType('Error_Occurred')
          setDisableVerifyBtn(false)
          //   setVerifyStatus(StatusEnum.Failed)
        }
      } catch (error) {
        console.error('An error occurred:', error)
      }
    }
  }

  // const callMeNow = async () => {
  //   dispatch(SetRegularFirstname(firstName))
  //   try {
  //     if (phoneNumber.length >= 4) {
  //       const response: any = await axios
  //         .post(AppConstants.API_URL + '/bland/generate-bland-call', {
  //           firstname: firstName,
  //           phone: '+' + phoneNumber.replace(/-/g, ''),
  //         })
  //         .then((res) => res)
  //         .catch((err) => err.response)

  //       if (response && response?.data?.code === 'success' && response?.data?.data?.status === 'success') {
  //         navigate('/bland/welcome')
  //       } else if (response && response?.data?.code === 'success' && response?.data?.data?.status === 'error') {
  //         console.log('Failed to trigger call', response?.data?.data?.message)
  //       }
  //     }
  //   } catch (error) {
  //     console.log('could not connect call.')
  //   }
  // }

  const getAllStudios = async () => {
    const response: any = await axios
      .get(AppConstants.API_URL + '/clinic', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })

    if (response?.data?.code === 'success') {
      dispatch(SetStudios(response?.data?.data))
      if (response?.data?.data) {
        if (response?.data.data?.length > 1) {
          dispatch(SetIsMoreThanOneStudio(true))
          navigate('/select-studio')
        } else {
          navigate('/physical-visit')
        }
      }
    } else {
      return false
    }
  }

  async function getProfile() {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })

      // if (response && response.data && response.data.data) {
      //   if (response.data.data.initialPayment) {
      //     if (!response.data.data.lastname || !response.data.data.email || !response.data.data.dob) {
      //       dispatch(SetSkipCongrats(true))
      //       navigate('/signup')
      //       return
      //     }
      //   }
      // }

      if (response && response?.data.code === 'success' && !response?.data?.data?.previousVisits) {
        postLeadStage()
        getAllStudios()
      }
      // if (response && response?.data.code === 'success' && !response?.data?.data?.profilPic) {
      //   navigate('/add-photo')
      // }
      //  else if (
      //   response &&
      //   response?.data.code === 'success' &&
      //   response?.data?.data?.profilPic &&
      //   !response?.data?.data?.initialWaiver
      // ) {
      //   navigate('/waiver-forms')
      // }
      // else if (
      //   response &&
      //   response?.data.code === 'success' &&
      //   response?.data?.data?.profilPic &&
      //   // response?.data?.data?.initialWaiver &&
      //   !response?.data?.data?.initialPayment
      // ) {
      //   navigate('/choose-plan')
      // }
      // else if (
      //   response &&
      //   response?.data.code === 'success' &&
      //   response?.data?.data?.profilPic &&
      //   response?.data?.data?.initialPayment &&
      //   (response?.data?.data?.patient?.screeningStatus === 'not_booked' || !response?.data?.data?.initialWaiver)
      // ) {
      //   navigate('/introductory-screening')
      // }
      else {
        navigate('/download')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const postLeadStage = async () => {
    await axios
      .post(
        AppConstants.API_URL + '/lead/stage/update',
        { stage: LeadStage.otp, channel: Channel.direct },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        },
      )
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err?.response
      })
  }

  useEffect(() => {
    if (phoneNumber.length >= 4 && firstname && firstname.length > 0) {
      setDisableButton(false)
    }
  }, [OTPScreen])

  useEffect(() => {
    dispatch(SetAvailablePlans([]))
    dispatch(SetSelectedPlan(null))
    dispatch(SetSelectedPlanInfo(null))
    dispatch(SetSelectedSlot(null))
    dispatch(SetSlotBookedAlready(false))
    dispatch(SetSlotNotFound(false))
    dispatch(SetPaymentDone(false))
    dispatch(SetSelectedStudio(null))
    dispatch(SetStudios([]))
    // dispatch(SetIsMoreThanOneStudio(false))
  }, [])

  if (!OTPScreen) {
    return (
      <div className={styles.container}>
        <Header
          headerText={''}
          showBackButton={true}
          onBack={() => {
            navigate('/name')
          }}
        ></Header>
        <div className={styles.content}>
          <div className={styles.textContainer}>
            <span>Nice to meet you, </span>
            <span style={{ textTransform: 'capitalize' }}>{firstname}.</span>
            <br></br>
            <span>What’s the best number to </span>
            <br></br>
            <span>reach you at?</span>
          </div>
          <div className={styles.formContainer}>
            <form>
              {/* <Grid item xs={12}> */}
              <Grid container>
                <Grid item xs={12}>
                  {/* <TextField
                    autoFocus
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      maxLength: 12,
                    }}
                    sx={
                      errorType === 'fieldEmpty' || errorType === 'formatInvalid' || errorType === 'userNot'
                        ? {
                            backgroundImage: "url('images/error.svg')",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 15px center',
                            textTransform: 'capitalize',
                          }
                        : {
                            '& .MuiInputBase-input': {
                              textTransform: 'capitalize',
                            },
                          }
                    }
                    placeholder="Enter Phone Number"
                    value={phoneNumber}
                    label="Phone Number"
                    name="phone"
                    error={errorType !== '' ? true : false}
                    onChange={(e) => {
                      const formattedValue = e.target.value
                        .replace(/\D/g, '')
                        .slice(0, 10)
                        .replace(/(\d{3})(\d{1,3})?(\d{0,4})?/, (_, first, second, third) => {
                          let result = first
                          if (second) result += '-' + second
                          if (third) result += '-' + third
                          return result
                        })
                      setErrorType('')
                      setPhoneNumber(formattedValue)
                      if (formattedValue.length === 12) {
                        setDisableButton(false)
                      } else {
                        setDisableButton(true)
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        if (!disableButton) {
                          sendOtp()
                        }
                      }
                    }}
                  /> */}
                  {/* <TextField
                    autoFocus
                    sx={
                      firstNameError
                        ? {
                            backgroundImage: "url('images/error.svg')",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 15px center',
                          }
                        : {
                            '& .MuiInputBase-input': {
                              textTransform: 'capitalize',
                            },
                          }
                    }
                    style={{ marginTop: '5px' }}
                    placeholder="Enter first name"
                    label="First Name"
                    name="firstName"
                    value={firstName}
                    error={firstNameError}
                    onChange={(e) => {
                      setFirstName(e.target.value)
                      if (e.target.value.length === 0 || phoneNumber.length < 4) {
                        setDisableButton(true)
                      } else {
                        setDisableButton(false)
                      }
                    }}
                    onKeyDown={(e) => handleKeyDown(e, 'phone')}
                  /> */}
                  <PhoneInput
                    inputProps={{
                      name: 'phone',
                      autoFocus: true,
                    }}
                    country={'us'}
                    value={phoneNumber}
                    preferredCountries={['us', 'ca', 'gb', 'sa', 'ae']}
                    priority={['us', 'ca', 'gb', 'sa', 'ae']}
                    preserveOrder={['preferredCountries']}
                    containerClass={styles.phoneCtn}
                    dropdownClass={styles.dropdown}
                    specialLabel=""
                    inputClass={styles.input}
                    buttonClass={styles.countryBtn}
                    countryCodeEditable={false}
                    onChange={(value, e: any) => {
                      setPhoneNumber(value)
                      setErrorType('')
                      const formatVal = value.slice(e.dialCode.length)
                      setFormattedPhoneNum(formatVal)
                      if (formatVal.length < 4 || firstname?.length === 0) {
                        setDisableButton(true)
                      } else {
                        setDisableButton(false)
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        if (!disableButton) {
                          sendOtp()
                        }
                      }
                    }}
                    inputStyle={
                      errorType === 'fieldEmpty' || errorType === 'formatInvalid' || errorType === 'userNot'
                        ? {
                            backgroundImage: "url('/images/error.svg')",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 15px center',
                            textTransform: 'capitalize',
                            border: '2px solid #F24343',
                          }
                        : {}
                    }
                  />
                </Grid>
                {/* </Grid> */}
                <div className={styles.phoneNumberConsent}>
                  100% Secure. By Submitting, you agree to KIRO’s Terms of Use and Privacy Policy. You consent to
                  receive text messages from us at the mobile number provided to book your appointment and receive
                  reminders. Message frequency depends on your activity. Reply STOP to opt-out.
                </div>
                <Grid item xs={12} sx={{ marginTop: '150px', position: 'absolute', width: '95%' }}>
                  {(errorType === 'fieldEmpty' ||
                    errorType === 'formatInvalid' ||
                    errorType === 'Not_User' ||
                    errorType === 'firstNameEmpty' ||
                    errorType === 'Error_Occurred') && (
                    <div className={styles.errorContainer}>
                      <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                        {errorType === 'fieldEmpty' && `Enter Your Phone Number`}
                        {errorType === 'formatInvalid' && `Invalid Phone Number`}
                        {errorType === 'firstNameEmpty' && `Enter Your First Name`}
                        {errorType === 'Not_User' && `User not found`}
                        {errorType === 'Error_Occurred' && `Oops! something went wrong , try again later`}
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          flex: '1',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          paddingRight: '12px',
                        }}
                      >
                        <img
                          onClick={() => {
                            setErrorType('')
                          }}
                          src={'/images/wrong.svg'}
                          style={{ cursor: 'pointer' }}
                        ></img>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </form>
            {/* <div style={{ marginTop: '1rem' }}>
              <Button
                // className={styles.submitBtn}
                id="send-otp"
                type="submit"
                sx={disableButton ? { opacity: '0.5' } : {}}
                children={'Send Passcode'}
                onClick={
                  disableButton
                    ? undefined
                    : () => {
                        sendOtp()
                      }
                }
              />
            </div> */}
            {/* <div className={styles.buttonContainer}>
              <div className={styles.scheduleText}>How would you like to schedule your appointment?</div>
              <Button
                className={styles.submitBtn}
                id="send-otp"
                type="submit"
                startIcon={<img src="/images/bookMyself.svg"></img>}
                sx={disableButton ? { opacity: '0.5' } : {}}
                children={'Continue'}
                onClick={
                  disableButton
                    ? undefined
                    : () => {
                        sendOtp()
                      }
                }
              ></Button>
            </div> */}
          </div>
        </div>
        <Footer
          buttonText={'Continue'}
          isLoading={false}
          shouldShowFour={true}
          shouldShowFive={true}
          shouldShowSix={true}
          shouldShowSeven={isMoreThanOneStudio}
          trackerIndex={2}
          isDisabled={disableButton}
          buttonId={'sign-in-button'}
          buttonType={'submit'}
          onButtonClick={() => {
            disableButton ? undefined : sendOtp()
          }}
        ></Footer>
      </div>
    )
  } else {
    return (
      <div className={style.container}>
        <Header
          headerText=""
          showBackButton={true}
          onBack={() => {
            setCode('')
            setOTPScreen(false)
            setErrorType('')
            setOtpError(false)
            setPhoneNumber(phoneNumber)
            setDisableVerifyBtn(true)
          }}
          hideIcon={false}
        />
        <div className={style.verifyOtpContainer} style={{ top: '31%', left: '50%' }}>
          <div className={style.formContainer}>
            <div>
              <p className={style.codeText}>
                Please enter the 6 digit code <br></br> sent to&nbsp;
                <b className={style.boldText}>{formatPhoneNumber(formattedPhoneNum)}</b> <br></br> through SMS.
              </p>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  if (!disableVerifyBtn) {
                    onVerify()
                  }
                }}
              >
                <Grid container spacing={2} sx={{ marginBottom: '12px', marginTop: '30px' }}>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        maxLength: 6,
                      }}
                      sx={
                        otpError
                          ? {
                              backgroundImage: "url('images/error.svg')",
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'right 15px center',
                              textTransform: 'capitalize',
                            }
                          : {}
                      }
                      value={code}
                      placeholder="Enter code"
                      label="Verification Code"
                      name="code"
                      // type="number"
                      error={otpError}
                      onChange={(e) => {
                        setCode(e.target.value)
                        setOtpError(false)
                        if (e.target.value.length >= 6) {
                          setDisableVerifyBtn(false)
                        } else {
                          setDisableVerifyBtn(true)
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
              <Grid item xs={12} sx={{ marginTop: '90px', position: 'absolute', width: '92%' }}>
                {otpError && (
                  <div className={style.errorContainer}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                      {errorType === 'Empty' && `Please enter passcode`}
                      {errorType === 'Invalid' && `Invalid Code`}
                      {errorType === 'Code_Expired' && `Oops! code expired, Click Resend`}
                      {errorType === 'Error_Occurred' && `Oops! something went wrong`}
                      {errorType === 'Not_User' && `User not found`}
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        paddingRight: '12px',
                      }}
                    >
                      <img
                        onClick={() => {
                          setOtpError(false)
                        }}
                        src={'images/wrong.svg'}
                        style={{ cursor: 'pointer' }}
                      ></img>
                    </div>
                  </div>
                )}
              </Grid>
            </div>
          </div>
          {/* <div className={styles.btnWrapper}>
            <Button
              id="sign-in-button"
              sx={disableVerifyBtn ? { opacity: '0.5' } : {}}
              type="submit"
              children={'Verify'}
              onClick={
                disableVerifyBtn
                  ? undefined
                  : () => {
                      onVerify()
                    }
              }
            />
          </div> */}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
            <div
              className={style.signUpText}
              onClick={() => {
                sendOtp()
              }}
            >
              Resend OTP{'>'}
            </div>
            <div
              className={style.signUpText}
              onClick={() => {
                setOTPScreen(false)
                setCode('')
                setDisableVerifyBtn(true)
                if (otpError || errorType !== '') {
                  setErrorType('')
                  setOtpError(false)
                }
              }}
            >
              Wrong Number?
            </div>
          </div>
        </div>
        <Footer
          buttonText={'Verify'}
          isLoading={false}
          trackerIndex={2}
          shouldShowFour={true}
          shouldShowFive={true}
          shouldShowSix={true}
          shouldShowSeven={isMoreThanOneStudio}
          isDisabled={disableVerifyBtn}
          buttonId={'sign-in-button'}
          buttonType={'submit'}
          onButtonClick={() => {
            disableVerifyBtn ? undefined : onVerify()
          }}
        ></Footer>
      </div>
    )
  }
}

export default Login
