import { createSlice } from '@reduxjs/toolkit'
import { studio } from 'redux/persist'

export interface IPlan {
  id: string
  name: string
  description: string
  campaignName: string
  type: string
  isDefault: boolean
  membershipPrice: number | null
  applicableToAllCycle: boolean | null
  stripeProductId: string
  count: number
  amount: number
  frequency: string | null
  maxCount: number
  isActive: boolean
  createdAt: string
  updatedAt: string
}

export interface ITime {
  index: number
  value: string
}

interface IScheduleInitialValues {
  scheduleObject: {
    appointmentTime: string
    timezone: string
    clinicId: string | undefined | null
  }
  index?: number | null
  value?: ITime | null
  date?: any
}

interface VisitCount {
  onDemandCount: number | null
  subscriptionCount: number | null
  previousVisits: boolean
}

interface PurchasedPlan {
  clientSecret: string
  paymentId: string
  paymentIntentId: string
}

export interface BookingState {
  availablePlansBooking: Array<IPlan>
  selectedPlanBooking: IPlan | null
  selectedSlotBooking: IScheduleInitialValues | null
  slotBookedAlreadyBooking: boolean
  slotNotFoundBooking: boolean
  paymentDoneBooking: any | null
  purchasedPlanBooking: PurchasedPlan | null
  visitCountBooking: VisitCount | null
  referralCodeBooking: string | null
  timeLeftToExpireBooking: number | null
  studiosBooking: Array<studio>
  selectedStudioBooking: studio | null
  isMoreThanOneStudioBooking: boolean
  leadType: string | null
  firstname: string
  channel: string
}

const initialState: BookingState = {
  availablePlansBooking: [],
  selectedPlanBooking: null,
  selectedSlotBooking: null,
  slotBookedAlreadyBooking: false,
  slotNotFoundBooking: false,
  paymentDoneBooking: null,
  purchasedPlanBooking: null,
  visitCountBooking: null,
  referralCodeBooking: null,
  timeLeftToExpireBooking: null,
  studiosBooking: [],
  selectedStudioBooking: null,
  isMoreThanOneStudioBooking: false,
  leadType: null,
  firstname: '',
  channel: '',
}

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    SetAvailablePlansBooking: (state, action) => {
      state.availablePlansBooking = action.payload
    },
    SetSelectedPlanBooking: (state, action) => {
      state.selectedPlanBooking = action.payload
    },
    SetSelectedSlotBooking: (state, action) => {
      state.selectedSlotBooking = action.payload
    },
    SetSlotBookedAlreadyBooking: (state, action) => {
      state.slotBookedAlreadyBooking = action.payload
    },
    SetSlotNotFoundBooking: (state, action) => {
      state.slotNotFoundBooking = action.payload
    },
    SetPurchasedPlanBooking: (state, action) => {
      state.purchasedPlanBooking = action.payload
    },
    SetVisitCountBooking: (state, action) => {
      state.visitCountBooking = action.payload
    },
    SetPaymentDoneBooking: (state, action) => {
      state.paymentDoneBooking = action.payload
    },
    SetReferralCodeBooking: (state, action) => {
      state.referralCodeBooking = action.payload
    },
    SetTimeLeftToExpireBooking: (state, action) => {
      state.timeLeftToExpireBooking = action.payload
    },
    SetStudiosBooking: (state, action) => {
      state.studiosBooking = action.payload
    },
    SetSelectedStudioBooking: (state, action) => {
      state.selectedStudioBooking = action.payload
    },
    SetIsMoreThanOneStudioBooking: (state, action) => {
      state.isMoreThanOneStudioBooking = action.payload
    },
    SetLeadType: (state, action) => {
      state.leadType = action.payload
    },
    SetFirstNameBooking: (state, action) => {
      state.firstname = action.payload
    },
    SetChannelBooking: (state, action) => {
      state.channel = action.payload
    },
  },
})

export default bookingSlice.reducer

export const {
  SetSelectedPlanBooking,
  SetSelectedSlotBooking,
  SetAvailablePlansBooking,
  SetSlotBookedAlreadyBooking,
  SetSlotNotFoundBooking,
  SetPurchasedPlanBooking,
  SetVisitCountBooking,
  SetPaymentDoneBooking,
  SetReferralCodeBooking,
  SetTimeLeftToExpireBooking,
  SetStudiosBooking,
  SetSelectedStudioBooking,
  SetIsMoreThanOneStudioBooking,
  SetLeadType,
  SetFirstNameBooking,
  SetChannelBooking,
} = bookingSlice.actions
