import { createTheme } from '@mui/material'

const fontFamily = 'Wix Madefor Display'

export const theme = createTheme({
  components: {
    // customize theme for button
    MuiButton: {
      defaultProps: { color: 'primary', variant: 'contained' },
      styleOverrides: {
        root: {
          width: '100%',
          height: '44px',
          fontFamily: fontFamily,
          textTransform: 'initial',
          backgroundColor: '#007F7C',
          padding: '15px 0px',
          fontSize: '18px',
          fontWeight: '700',
          lineHeight: '18px',
          borderRadius: '6px',
          boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.20)',
          '&:hover': {
            backgroundColor: '#007F7C',
          },
          '@media (min-width: 600px)': {
            height: '42px',
            fontSize: '16px',
          },
        },
      },
    },

    // MuiInputLabel: {
    //   styleOverrides: {
    //     root: {
    //       color: 'white', // Customize the color for the placeholder
    //       fontFamily: fontFamily,
    //     },
    //   },
    // },

    // customize theme for checkbox
    // MuiCheckbox: {
    //   styleOverrides: {
    //     root: {
    //       width: '16px',
    //       height: '16px',
    //       borderRadius: '8px',
    //       color: '#E5E7EB',
    //       '&.Mui-checked': {
    //         color: '#E5E7EB', // Set the color for the checked state
    //         borderRadius: '8px',
    //       },
    //       '&.MuiCheckbox-indeterminate': {
    //         color: '#E5E7EB', // Set the color for the indeterminate state
    //         borderRadius: '8px',
    //       },
    //     },
    //   },
    // },
    // customize theme for select
    MuiSelect: {
      styleOverrides: {
        root: {
          width: '100%',
          height: '31px',
          border: '1px solid #2A2D56',
          borderRadius: '6px',
          fontFamily: fontFamily,
          color: '#E5E7EB',
          '& .MuiSelect-select': {
            fontFamily: fontFamily,
            fontSize: '15px',
            '@media (min-width: 600px)': {
              fontSize: '15px',
            },
            color: '#E5E7EB',
            borderRadius: '6px',
            fontWeight: 700,
            '&:focus': {
              backgroundColor: 'transparent',
            },
          },
          '&.Mui-error': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#F24343',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#F24343',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#F24343',
            },
          },
          '& .MuiSelect-icon': {
            color: '#E5E7EB',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#32344B',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D2D3E0',
          },

          '&.Mui-focused': {
            borderRadius: '6px',
            fontFamily: fontFamily,
            '& fieldset': {
              fontFamily: fontFamily,
            },
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily,
        },
        paper: {
          color: '#E5E7EB',
          borderRadius: '4px',
          background: '#050624',
          border: '1px solid #2A2D56',
          padding: '2px',
          '@media (max-width: 700px)': {
            width: '154px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          marginBottom: '8px',
          fontSize: '16px',
          margin: '3px',
          paddingLeft: '10px',
          '&.Mui-selected': {
            background: '#2A2D56',
            color: '#E5E7EB',
          },
          '&.Mui-selected:hover': {
            background: '#2A2D56',
            color: '#E5E7EB',
          },
          '&:last-of-type': {
            marginBottom: '-5px',
          },
          '&:first-of-type': {
            marginTop: '-4px',
          },

          // '@media (max-width: 700px)': {
          //   height: '24px !important',
          // },
          // '&:hover': {
          //   background: '#2A2D56',
          //   color: '#E5E7EB',
          // },
          // '& .MuiMenuItem-root.Mui-selected': {
          //   backgroundColor: 'red !important', // Change this to your preferred color
          // },
          // '& .MuiMenuItem-root.Mui-selected:hover': {
          //   backgroundColor: 'red !important', // Hover state
          // },
          // '&:focus': {
          //   background: '#2A2D56',
          //   color: '#E5E7EB',
          // },
          // '&.Mui-focusVisible': {
          //   background: '#2A2D56', // Focus state
          //   color: '#E5E7EB',
          // },
        },
      },
    },

    // old version
    // MuiSelect: {
    //   styleOverrides: {
    //     root: {
    //       width: '100%',
    //       height: '54px',
    //       '@media (min-width: 600px)': {
    //         height: '46.5px',
    //       },
    //       borderRadius: '6px',
    //       fontFamily: fontFamily,
    //       color: '#E5E7EB',
    //       '& .MuiSelect-select': {
    //         fontFamily: fontFamily,
    //         fontSize: '16px',
    //         '@media (min-width: 600px)': {
    //           fontSize: '15px',
    //         },
    //         color: '#E5E7EB',
    //         borderRadius: '6px',
    //         fontWeight: 400,
    //         '&:focus': {
    //           backgroundColor: 'transparent',
    //         },
    //       },
    //       '&.Mui-error': {
    //         '.MuiOutlinedInput-notchedOutline': {
    //           borderColor: '#F24343',
    //         },
    //         '&:hover .MuiOutlinedInput-notchedOutline': {
    //           borderColor: '#F24343',
    //         },
    //         '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    //           borderColor: '#F24343',
    //         },
    //       },
    //       '& .MuiSelect-icon': {
    //         color: '#E5E7EB',
    //       },
    //       '& .MuiOutlinedInput-notchedOutline': {
    //         border: '2px solid #32344B',
    //         borderColor: '#32344B',
    //       },
    //       '&:hover .MuiOutlinedInput-notchedOutline': {
    //         borderColor: '#32344B',
    //       },
    //       '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    //         borderColor: '#D2D3E0',
    //       },

    //       '&.Mui-focused': {
    //         borderRadius: '6px',
    //         fontFamily: fontFamily,
    //         '& fieldset': {
    //           fontFamily: fontFamily,
    //         },
    //       },
    //     },
    //   },
    // },
    // MuiMenu: {
    //   styleOverrides: {
    //     root: {
    //       fontFamily: fontFamily,
    //     },
    //     paper: {
    //       color: '#E5E7EB',
    //       borderRadius: '4px',
    //       background: '#161834',
    //       padding: '8px',
    //     },
    //   },
    // },
    // MuiMenuItem: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: '4px',
    //       marginBottom: '8px',
    //       '&:first-of-type': {
    //         background: 'transparent', // Set to transparent for the first MenuItem
    //       },
    //       '&:nth-of-type(2)': {
    //         background: 'transparent', // Set background color for the second MenuItem
    //       },
    //       '&.Mui-selected': {
    //         background: 'transparent',
    //         color: '#E5E7EB',
    //       },
    //       '&:hover': {
    //         background: '#007F7C',
    //         color: '#E5E7EB',
    //       },
    //     },
    //   },
    // },
    // // customize theme for input field

    MuiTextField: {
      defaultProps: { variant: 'outlined' },
      styleOverrides: {
        root: {
          width: '100%',
          height: '54px',
          '@media (min-width: 600px)': {
            height: '52px',
          },
          '& .MuiInputBase-input': {
            fontFamily: fontFamily,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#E5E7EB',
            '@media (min-width: 600px)': {
              fontSize: '14px',
            },
            fontWeight: 400,
            '&::placeholder': {
              fontSize: '18px',
              lineHeight: '24px',
              color: '#E5E7EB',
              fontWeight: 400,
              '@media (min-width: 600px)': {
                fontSize: '14px',
              },
            },
          },
          '& .MuiFormHelperText-root': {
            color: 'red',
          },
          '& .MuiInputBase-root': {
            borderRadius: '6px',
            fontFamily: fontFamily,
            // background: 'red',

            '&.Mui-error': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#F24343',
              },
            },
            '&.Mui-focused': {
              fontSize: '16px',
              fontFamily: fontFamily,
            },
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '6px',
            fontFamily: fontFamily,
            '@media (min-width: 600px)': {
              height: '45px', // Adjust the height for smaller screens
            },

            '&:hover': {
              '& fieldset': {
                border: '2px solid #32344B',
                fontFamily: fontFamily,
              },
            },
            '& fieldset': {
              border: '2px solid #32344B',
              fontFamily: fontFamily,
              '@media (min-width: 600px)': {
                height: '52px',
              },
              // paddingBottom: '35px',
            },
            '&.Mui-focused': {
              borderRadius: '6px',
              fontFamily: fontFamily,

              '& fieldset': {
                border: '2px solid #D2D3E0',
                fontFamily: fontFamily,
              },
            },
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            '& .MuiInputLabel-root': {
              fontSize: '16px',
              fontWeight: 0,
              lineHeight: '24px',
              background: '#050624',
              paddingLeft: 10,
              marginLeft: -7,
              paddingRight: 8,
              '@media (min-width: 600px)': {
                lineHeight: '14px',
              },
              // background: 'red',
              color: '#E5E7EB',
              '&.Mui-error': {
                '&.Mui-focused': {
                  color: '#F24343',
                },
              },
              '&.Mui-focused': {
                color: '#E5E7EB',
              },
              '&.MuiFormLabel-filled': {
                color: '#E5E7EB',
              },
            },
          },
        },
      ],
    },
  },
  typography: { allVariants: { fontFamily } },
})
