import { useEffect, useState } from 'react'
import { Button, Dialog, DialogTitle } from '@mui/material'
import axios from 'axios'
import { format } from 'date-fns'
import ReactGA from 'react-ga4'
import { useNavigate, useParams } from 'react-router-dom'
import { AppConstants, Channel, LeadStage } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { SetPurchasedPlanReferral, SetSelectedSlotReferralFlow, SetTimeLeftReferral } from 'redux/Referral/persist'
import { useAppDispatch, useAppSelector } from 'redux/store'
import styles from './ConfirmAppointment.module.scss'

const ConfirmAppointment = () => {
  const styless: any = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //   position: 'absolute' as 'absolute',
    //   top: '44%',
    //   left: '47.5%',
    //   transform: 'translate(-50%, -50%)',
    width: 300,
    height: 170,
    overflow: 'hidden',
    backgroundColor: '#050624',
    color: 'white',
    borderRadius: '8px',
    p: 4,
  }
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const appointmentTime = useAppSelector((state) => state.referralPersist.selectedSlotReferralFlow)
  const studios = useAppSelector((state) => state.referralPersist.studiosReferral)
  const selectedStudio = useAppSelector((state) => state.referralPersist.studioReferral)
  const isMoreThanOneStudio = useAppSelector((state) => state.referralPersist.isMoreThanOneStudioReferral)
  const isPaymentDone = useAppSelector((state) => state.referralPersist.isPaymentDoneReferral)
  const plan = useAppSelector((state) => state.referralPersist.paymentPlanReferral)
  console.log(plan, 'plans plans')
  // const slotBookedAlready = useAppSelector((state) => state.slotBookedAlreadyReferral.slotBookedAlreadyReferralFlow)
  const [error, setError] = useState(false)
  const [slotNotFound, setSlotNotFound] = useState(false)
  const timeLeft: any = useAppSelector((state) => state.referralPersist.timeLeft)

  const schedulePhysicalVisit = async () => {
    if (appointmentTime?.scheduleObject) {
      const response: any = await axios
        .post(AppConstants.API_URL + '/visit/chiropractor/appointment', appointmentTime.scheduleObject, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })

      if (response?.data?.code === 'success') {
        // ReactGA.event('conversion', {
        //   send_to: process.env.REACT_APP_GA_CONVERSION_ID || 'AW-11337579902/40e2CN_xnqkZEP76lp4q',
        //   value: 0,
        //   currency: 'USD',
        // })
        navigate(`/referral/${id}/welcome`)
      } else {
        if (response?.data?.code === 'slot_booked_already') {
          setError(true)
          dispatch(SetSelectedSlotReferralFlow(null))
        } else if (response?.data?.code === 'slot_not_found') {
          setSlotNotFound(true)
          dispatch(SetSelectedSlotReferralFlow(null))
        }
        return false
      }
    } else {
      return false
    }
  }

  async function purchasePlan(selectedPlanId: string | undefined) {
    try {
      const response: any = await axios
        .post(
          `${AppConstants.API_URL}/payment-plan/purchase`,
          { planId: selectedPlanId, quantity: 1 },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
            },
          },
        )
        .then((res) => {
          if (res && res?.data && res?.data?.data) {
            dispatch(SetPurchasedPlanReferral(res?.data?.data))
            navigate(`/referral/${id}/payment`)
          }
        })
        .catch((err) => {
          if (err) {
            alert('Something went wrong. Please try again.')
          }
        })
    } catch (error) {
      console.error(error)
    }
  }
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  const postLeadStage = async () => {
    await axios
      .post(
        AppConstants.API_URL + '/lead/stage/update',
        {
          stage: LeadStage.confirmation,
          channel: plan && plan?.length > 0 ? (plan[0]?.amount > 0 ? Channel.referral : Channel.free) : null,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        },
      )
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err?.response
      })
  }

  useEffect(() => {
    postLeadStage()
  }, [])

  useEffect(() => {
    dispatch(SetTimeLeftReferral(timeLeft ?? 299))
  }, [])

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        dispatch(SetTimeLeftReferral(timeLeft && timeLeft - 1))
      }, 1000)

      return () => clearInterval(timerId)
    }
  }, [timeLeft])

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Referral|confirm-visit',
      page_location: `/referral/${id}/confirm-visit`,
    })
  }, [])

  return (
    <>
      <div className={styles.container}>
        <Header
          headerText=""
          showBackButton={true}
          onBack={() => {
            navigate(`/referral/${id}/physical-visit`)
          }}
        ></Header>
        <div className={styles.locationContainer}>
          <div className={styles.confirmHeader}>Confirm your appointment.</div>
          {studios && (
            <div className={styles.locationDetails}>
              <div className={styles.locationTextContainer}>
                <div className={styles.locationWrapper}>
                  <div className={styles.appointmentTime}>
                    {appointmentTime
                      ? format(new Date(appointmentTime?.scheduleObject.appointmentTime), 'EEEE, MMM dd')
                      : ''}
                    <div>
                      {appointmentTime
                        ? format(new Date(appointmentTime?.scheduleObject.appointmentTime), 'h:mm a')
                        : ''}
                    </div>
                  </div>
                  <div>
                    <div className={styles.locationText} style={{ fontWeight: 700 }}>
                      {isMoreThanOneStudio ? selectedStudio?.name : studios[0]?.name}
                    </div>
                    <div className={styles.locationText}>
                      {isMoreThanOneStudio ? selectedStudio?.address : studios[0]?.address}
                    </div>
                    {isMoreThanOneStudio ? (
                      <div className={styles.cityAndState}>
                        <div style={{ marginRight: '4px' }}>{selectedStudio?.city.name + ','}</div>
                        <div style={{ marginRight: '4px', textTransform: 'uppercase' }}>{selectedStudio?.state}</div>
                        <div>{selectedStudio?.zipCode}</div>
                      </div>
                    ) : (
                      <div className={styles.cityAndState}>
                        <div style={{ marginRight: '4px' }}>{studios[0]?.city.name + ','}</div>
                        <div style={{ marginRight: '4px', textTransform: 'uppercase' }}>{studios[0]?.state}</div>
                        <div>{studios[0]?.zipCode}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <img
                style={{ width: '145px', height: '150px', cursor: 'pointer', objectFit: 'cover' }}
                className={styles.mapImageItem}
                src={isMoreThanOneStudio ? selectedStudio?.mapImage || '' : studios[0]?.mapImage || ''}
                alt="map"
                onClick={() => {
                  if (studios && selectedStudio && selectedStudio?.mapLocation && isMoreThanOneStudio) {
                    window.open(selectedStudio.mapLocation, '_blank')
                  } else if (studios && studios.length === 1 && studios[0]?.mapLocation) {
                    window.open(studios[0].mapLocation, '_blank')
                  }
                  //   window.location.href =
                  //     'https://www.google.com/maps/place/302+Mott+St,+New+York,+NY+10012,+USA/@40.7248592,-73.9940347'
                }}
              />
            </div>
          )}

          {error && (
            <Dialog
              onClose={() => {
                // setError(false)
              }}
              open={error}
              PaperProps={{ style: styless }}
              className={styles.modalPopUp}
            >
              <DialogTitle style={{ fontSize: '14px', marginTop: '0rem' }}>
                We’re sorry, the slot you picked has been booked already. Please select another slot.
              </DialogTitle>
              <Button
                color="primary"
                style={{
                  width: 'fit-content',
                  marginBottom: '1rem',
                  height: '2rem',
                  padding: '0px 8px 0px 8px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  setError(false)
                  // dispatch(SetSlotBookedAlreadyReferralFlow(false))
                  navigate(`/referral/${id}/physical-visit`)
                }}
              >
                Reschedule
              </Button>
            </Dialog>
          )}
          {slotNotFound && (
            <Dialog
              onClose={() => {
                // setError(false)
              }}
              open={slotNotFound}
              PaperProps={{ style: styless }}
              className={styles.modalPopUp}
            >
              <DialogTitle style={{ fontSize: '14px', marginTop: '0rem' }}>
                We’re sorry, the slot you picked is no longer available. Please select another slot.
              </DialogTitle>
              <Button
                color="primary"
                style={{
                  width: 'fit-content',
                  marginBottom: '1rem',
                  height: '2rem',
                  padding: '0px 8px 0px 8px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  setSlotNotFound(false)
                  navigate(`/referral/${id}/physical-visit`)
                }}
              >
                Reschedule
              </Button>
            </Dialog>
          )}
        </div>
        {plan && plan[0]?.amount !== 0 && (
          <div className={styles.offerDetails}>
            <span className={styles.offerDetailsHeader}>Offer Details</span>
            <div className={styles.offerText}>
              Due to limited availability for this promotion, we require a non-refundable payment of
              <b> ${plan[0]?.amount / 100}</b> to secure your spot. Please complete your payment below. We're looking
              forward to meeting you in our studio!
            </div>
          </div>
        )}
        {plan && plan[0]?.amount !== 0 && (
          <div className={styles.offerExpires}>
            Offer Expires In: <span className={styles.timer}>{formatTime(timeLeft || 0)}</span>
          </div>
        )}
        <Footer
          buttonText={
            plan && plan[0]?.amount === 0
              ? 'Confirm'
              : !isPaymentDone && plan && plan[0]?.amount > 0
              ? `Confirm and Pay $${plan[0]?.amount / 100}`
              : 'Confirm'
          }
          isLoading={false}
          trackerIndex={isMoreThanOneStudio ? 5 : 4}
          shouldShowFour={true}
          shouldShowFive={true}
          shouldShowSix={isMoreThanOneStudio}
          isDisabled={false}
          buttonType={'button'}
          onButtonClick={() => {
            if (!isPaymentDone && appointmentTime?.scheduleObject && plan && plan[0]?.amount > 0) {
              purchasePlan(plan[0]?.id)
            } else {
              schedulePhysicalVisit()
            }
          }}
        ></Footer>
      </div>
    </>
  )
}

export default ConfirmAppointment
