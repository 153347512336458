import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import ReactGA from 'react-ga4'
import { useNavigate, useParams } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
import TextField from 'components/shared/textField'
import { useAppDispatch, useAppSelector } from 'redux/store'
import styles from './Name.module.scss'

const NameReferral: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [firstname, setFirstname] = useState('')
  const [disableBtn, setDisableBtn] = useState(true)
  const isMoreThanOneStudio = useAppSelector((state) => state.referralPersist.isMoreThanOneStudioReferral)

  const onContinue = () => {
    if (firstname.length !== 0) {
      localStorage.setItem(AppConstants.USER_FIRST_NAME, firstname)
      navigate(`/referral/${id}/login`)
    }
  }

  useEffect(() => {
    if (id === 'google') {
      ReactGA.event('page_view', {
        page_title: 'Referral|Name',
        page_location: `/referral/${id}/name`,
      })
    }
  }, [id])

  useEffect(() => {
    if (id === 'facebook') {
      //@ts-ignore
      fbq('track', 'ViewContent')
    }
  }, [id])

  useEffect(() => {
    localStorage.setItem(AppConstants.USER_FIRST_NAME, '')
  }, [])

  return (
    <div className={styles.container}>
      <Header
        headerText={''}
        showBackButton={true}
        onBack={() => {
          navigate(`/referral/${id}`)
        }}
      ></Header>
      <div className={styles.content}>
        <div className={styles.textContainer}>
          <span>Let's get you scheduled</span>
          <br></br>
          <span>with our doctors. What's</span>
          <br></br>
          <span>your first name?</span>
        </div>
        <div className={styles.formContainer}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                autoFocus
                style={{ marginTop: '5px' }}
                sx={{
                  '& .MuiInputBase-input': {
                    textTransform: 'capitalize',
                  },
                }}
                placeholder="Enter first name"
                label="First Name"
                name="firstname"
                value={firstname}
                onChange={(e) => {
                  setFirstname(e.target.value)
                  if (e.target.value.length === 0 && !disableBtn) {
                    setDisableBtn(true)
                  } else {
                    if (disableBtn) {
                      setDisableBtn(false)
                    }
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    disableBtn ? undefined : onContinue()
                  }
                }}
                className={styles.firstNameInput}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer
        buttonText={'Continue'}
        isLoading={false}
        shouldShowFour={true}
        shouldShowFive={true}
        shouldShowSix={isMoreThanOneStudio}
        trackerIndex={1}
        isDisabled={disableBtn}
        buttonId={'continue-btn'}
        buttonType={'button'}
        onButtonClick={() => {
          disableBtn ? undefined : onContinue()
        }}
      ></Footer>
    </div>
  )
}

export default NameReferral
