import React, { useEffect, useMemo, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import Header from 'components/Header'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { CheckoutForm } from './CheckoutForm'
import styles from './Payment.module.scss'

const PaymentBooking = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [disableBackButton, setDisableBackButton] = useState(false)

  const plans = useAppSelector((state) => state.booking.availablePlansBooking)
  const purchasedPlan = useAppSelector((state) => state.booking.purchasedPlanBooking)
  const selectedPlan = useAppSelector((state) => state.booking.selectedPlanBooking)
  const leadType = useAppSelector((state) => state.booking.leadType)

  //cardPayment
  const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY || ''
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

  const options: StripeElementsOptions = {
    clientSecret: purchasedPlan?.clientSecret,
    appearance: {
      theme: 'night',
      variables: {
        colorBackground: '#050624',
      },
    },
  }

  const plan = useMemo(() => {
    if (plans) {
      const filterOnDemand = plans?.filter((p) => p.type === 'on-demand')
      return filterOnDemand
    } else {
      return null
    }
  }, [plans])

  return (
    <div>
      <div className={styles.paymentContainer}>
        <Header
          headerText="Add Payment Details"
          showBackButton={true}
          onBack={() => {
            disableBackButton ? undefined : navigate(`/booking/${id}/confirm-visit`)
          }}
        ></Header>
        <Elements stripe={stripePromise} options={options}>
          <div className={styles.paymentContainerDetails}>
            <CheckoutForm
              clientSecret={purchasedPlan?.clientSecret}
              planId={purchasedPlan?.paymentId}
              purchaseAmount={leadType === null ? (selectedPlan?.amount || 60) / 100 : (plan?.[0]?.amount || 60) / 100}
              disableBackButton={() => {
                setDisableBackButton(true)
              }}
              enableBackButton={() => {
                setDisableBackButton(false)
              }}
            ></CheckoutForm>
          </div>
        </Elements>
      </div>
    </div>
  )
}

export default PaymentBooking
