import React, { useEffect } from 'react'
import { ThemeProvider } from '@mui/material'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import { Provider } from 'react-redux'
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import 'react-toastify/dist/ReactToastify.css'
import 'styles/global.scss'
import DownloadPage from 'feature/download/download'
import EmailSuccess from 'feature/emailVerificationPage'
import PaymentBland from 'pages/Bland/Payment'
import Booking from 'pages/Booking'
import ChoosePlanBooking from 'pages/Booking/ChoosePlan'
import ConfirmAppointmentBooking from 'pages/Booking/ConfirmAppointment'
import CongratsBooking from 'pages/Booking/Congrats'
import DobBooking from 'pages/Booking/Dob'
import DownloadBooking from 'pages/Booking/Download'
import PaymentBooking from 'pages/Booking/Payment'
import PhysicalVisitBooking from 'pages/Booking/PhysicalVisit'
import SelectStudioBooking from 'pages/Booking/SelectStudio'
import SignupBooking from 'pages/Booking/Signup'
import CardPayment from 'pages/cardPayment'
import Home from 'pages/home'
import ClinicWelcomeOffers from 'pages/Offers/ClinicWelcome'
import ConfirmVisitOffers from 'pages/Offers/ConfirmAppointment'
import CongratsOffers from 'pages/Offers/Congrats'
import DobOffers from 'pages/Offers/Dob'
import DownloadOffers from 'pages/Offers/Download'
import HomeOffers from 'pages/Offers/Home'
import LoginOffers from 'pages/Offers/Login'
import NameOffers from 'pages/Offers/Name'
import PaymentOffers from 'pages/Offers/Payment'
import PhysicalVisitOffers from 'pages/Offers/PhysicalVisit'
import SelectStudioOffers from 'pages/Offers/SelectStudio'
import SignupOffers from 'pages/Offers/Signup'
import Schedule from 'pages/onboard'
import PaymentMethod from 'pages/paymentMethod'
import AddPhotoReferral from 'pages/Referral/AddPhoto'
import ClinicWelcomeReferral from 'pages/Referral/ClinicWelcome'
import ConfirmVisitReferral from 'pages/Referral/ConfirmAppointment'
import CongratsReferral from 'pages/Referral/Congrats'
import DownloadReferral from 'pages/Referral/Download'
import HomeReferral from 'pages/Referral/Home'
import LoginReferral from 'pages/Referral/Login'
import NameReferral from 'pages/Referral/Name'
import PaymentReferral from 'pages/Referral/Payment'
import PhysicalVisitReferral from 'pages/Referral/PhysicalVisit'
import SelectStudioReferral from 'pages/Referral/SelectStudio'
import SignupReferral from 'pages/Referral/Signup'
import SamplePage from 'pages/sample/SamplePage'
import Register from 'pages/signup'
import AddPhoto from 'pages/V1/AddPhoto'
import BlandWelcome from 'pages/V1/BlandWelcome'
import ChoosePlan from 'pages/V1/ChoosePlan'
import CompleteRegistration from 'pages/V1/CompleteRegistration'
import ConfirmAppointment from 'pages/V1/ConfirmAppointment'
import Congrats from 'pages/V1/Congrats'
import Download from 'pages/V1/Download'
import EmailVerification from 'pages/V1/EmailVerification'
import HomeRegular from 'pages/V1/Home'
import Intro from 'pages/V1/Intro'
import IntroductoryScreening from 'pages/V1/IntroductoryScreening'
import Login from 'pages/V1/Login'
import NameRegular from 'pages/V1/Name'
import Onboard from 'pages/V1/Onboard'
import Payment from 'pages/V1/Payment'
import PhysicalVisit from 'pages/V1/PhysicalVisit'
import SelectStudio from 'pages/V1/SelectStudio'
import Signup from 'pages/V1/Signup'
import WaiverForm from 'pages/V1/WaiverForm/WaiverForm'
import Welcome from 'pages/V1/Welcome'
import store, { persister } from 'redux/store'
import reportWebVitals from 'reportWebVitals'
import { theme } from 'styles/theme'

// import Login from './pages/login'

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomeRegular></HomeRegular>,
  },
  {
    path: '/name',
    element: <NameRegular></NameRegular>,
  },
  {
    path: '/login',
    element: <Login></Login>,
  },
  {
    path: '/bland/welcome',
    element: <BlandWelcome></BlandWelcome>,
  },
  // {
  //   path: '/signup',
  //   element: <Signup></Signup>,
  // },
  {
    path: '/add-photo',
    element: <AddPhoto></AddPhoto>,
  },
  // {
  //   path: '/waiver-forms',
  //   element: <WaiverForm></WaiverForm>,
  // },
  {
    path: '/choose-plan',
    element: <ChoosePlan></ChoosePlan>,
  },
  {
    path: '/select-studio',
    element: <SelectStudio></SelectStudio>,
  },
  {
    path: '/physical-visit',
    element: <PhysicalVisit></PhysicalVisit>,
  },
  {
    path: '/confirm-visit',
    element: <ConfirmAppointment></ConfirmAppointment>,
  },
  {
    path: '/payment',
    element: <Payment></Payment>,
  },
  {
    path: '/welcome',
    element: <Congrats></Congrats>,
  },
  {
    path: '/download',
    element: <Download></Download>,
  },
  {
    path: '/verify-email',
    element: <EmailVerification></EmailVerification>,
  },
  {
    path: '/intro',
    element: <Intro></Intro>,
  },
  {
    path: '/tabs/calendar',
    element: <Download></Download>,
  },

  // referral flow
  {
    path: '/referral/:id',
    element: <HomeReferral></HomeReferral>,
  },
  {
    path: '/referral/:id/name',
    element: <NameReferral></NameReferral>,
  },
  {
    path: '/referral/:id/login',
    element: <LoginReferral></LoginReferral>,
  },
  // {
  //   path: '/referral/:id/signup',
  //   element: <SignupReferral></SignupReferral>,
  // },
  {
    path: '/referral/:id/add-photo',
    element: <AddPhotoReferral></AddPhotoReferral>,
  },
  {
    path: '/referral/:id/select-studio',
    element: <SelectStudioReferral></SelectStudioReferral>,
  },
  {
    path: '/referral/:id/physical-visit',
    element: <PhysicalVisitReferral></PhysicalVisitReferral>,
  },
  {
    path: '/referral/:id/confirm-visit',
    element: <ConfirmVisitReferral></ConfirmVisitReferral>,
  },
  {
    path: '/referral/:id/payment',
    element: <PaymentReferral></PaymentReferral>,
  },
  {
    path: '/referral/:id/welcome',
    element: <CongratsReferral></CongratsReferral>,
  },
  {
    path: '/referral/:id/download',
    element: <DownloadReferral></DownloadReferral>,
  },
  {
    path: '/clinic',
    element: <ClinicWelcomeReferral></ClinicWelcomeReferral>,
  },

  // Offers flow
  {
    path: '/offers/:id/',
    element: <HomeOffers></HomeOffers>,
  },
  {
    path: '/offers/:id/name',
    element: <NameOffers></NameOffers>,
  },
  {
    path: '/offers/:id/login',
    element: <LoginOffers></LoginOffers>,
  },
  // {
  //   path: '/offers/:id/',
  //   element: <SignupOffers></SignupOffers>,
  // },
  {
    path: '/offers/:id/dob',
    element: <DobOffers></DobOffers>,
  },
  {
    path: '/offers/:id/select-studio',
    element: <SelectStudioOffers></SelectStudioOffers>,
  },
  {
    path: '/offers/:id/physical-visit',
    element: <PhysicalVisitOffers></PhysicalVisitOffers>,
  },
  {
    path: '/offers/:id/confirm-visit',
    element: <ConfirmVisitOffers></ConfirmVisitOffers>,
  },
  {
    path: '/offers/:id/payment',
    element: <PaymentOffers></PaymentOffers>,
  },
  // {
  //   path: '/offers/:id/signup',
  //   element: <SignupOffers></SignupOffers>,
  // },
  {
    path: '/offers/:id/welcome',
    element: <CongratsOffers></CongratsOffers>,
  },
  {
    path: '/offers/:id/download',
    element: <DownloadOffers></DownloadOffers>,
  },
  {
    path: '/clinic',
    element: <ClinicWelcomeOffers></ClinicWelcomeOffers>,
  },

  // Booking
  {
    path: '/visit/booking/:id',
    element: <Booking></Booking>,
  },
  // {
  //   path: '/booking/:id/signup',
  //   element: <SignupBooking></SignupBooking>,
  // },
  {
    path: '/booking/:id/choose-plan',
    element: <ChoosePlanBooking></ChoosePlanBooking>,
  },
  {
    path: '/booking/:id/select-studio',
    element: <SelectStudioBooking></SelectStudioBooking>,
  },
  {
    path: '/booking/:id/physical-visit',
    element: <PhysicalVisitBooking></PhysicalVisitBooking>,
  },
  {
    path: '/booking/:id/confirm-visit',
    element: <ConfirmAppointmentBooking></ConfirmAppointmentBooking>,
  },
  {
    path: '/booking/:id/payment',
    element: <PaymentBooking></PaymentBooking>,
  },
  {
    path: '/booking/:id/welcome',
    element: <CongratsBooking></CongratsBooking>,
  },
  {
    path: '/booking/:id/download',
    element: <DownloadBooking></DownloadBooking>,
  },
  {
    path: '/booking/:id/dob',
    element: <DobBooking></DobBooking>,
  },

  {
    path: '/direct/purchase/:id',
    element: <PaymentBland></PaymentBland>,
  },

  // {
  //   path: '/complete-registration',
  //   element: <CompleteRegistration></CompleteRegistration>,
  // },
  // {
  //   path: '/onboard-schedule',
  //   element: <Onboard></Onboard>,
  // },
  // {
  //   path: '/introductory-screening',
  //   element: <IntroductoryScreening></IntroductoryScreening>,
  // },
  // {
  //   path: '/sample',
  //   element: <SamplePage></SamplePage>,
  // },
  // {
  //   path: '/download',
  //   element: <DownloadPage></DownloadPage>,
  // },
  // {
  //   path: '/verify-email',
  //   element: <EmailSuccess></EmailSuccess>,
  // },
  // {
  //   path: '/signup',
  //   element: <Register></Register>,
  // },
  // {
  //   path: '/login',
  //   element: <Login></Login>,
  // },
  // {
  //   path: '/add-photo',
  //   element: <AddPhoto></AddPhoto>,
  // },

  // {
  //   path: '/card-payment',
  //   element: <CardPayment></CardPayment>,
  // },
  // {
  //   path: '/payment-method',
  //   element: <PaymentMethod></PaymentMethod>,
  // },
  // {
  //   path: '/waiver-forms',
  //   element: <Waiverform></Waiverform>,
  // },
  // {
  //   path: '/welcome',
  //   element: <Welcome></Welcome>,
  // },
  // {
  //   path: '/choose-plan',
  //   element: <ChoosePlan></ChoosePlan>,
  // },
  // {
  //   path: '/onboard-schedule',
  //   element: <Schedule></Schedule>,
  // },
])
//PROD//G-MQL5JQSXFG
//DEV//'G-7SMD7TVSHL'
ReactGA.initialize(process.env.REACT_APP_GA_GTAG || 'G-MQL5JQSXFG', {
  gtagOptions: {
    send_page_view: false,
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ThemeProvider theme={theme}>
    <ToastContainer position="top-right" closeOnClick />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <RouterProvider router={router}></RouterProvider>
      </PersistGate>
    </Provider>
  </ThemeProvider>,
)

// export const analytics = new AnalyticsBrowser()
// analytics.load({ writeKey: '3DqDR7sEg2qnkEWEbv5fopNsmEqICPq3' })

// Initialize React Ga with your tracking ID

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
