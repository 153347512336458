import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import authReducer from 'redux/auth'
import bookingReducer from 'redux/booking'
import bookingNonPersistReducer from 'redux/booking/nonPersist'
import offersReducer from 'redux/Offers/nonPersist'
import {
  selectedSlotOfferFlowPersist,
  paymentPlanOfferFlowPersist,
  purchasedPlanOfferFlowPersist,
  paymentDoneOfferFlowPersist,
  slotBookedOfferFlowPersist,
  visitCountOfferFlowPersist,
  timeLeftOfferFlowPersist,
  selectedStudioOffersPersist,
  studiosOffersPersist,
  isMoreThanOneStudioOffersPersist,
  slotNotFoundOfferFlowPersist,
  timeLeftHomeOfferFlowPersist,
} from 'redux/Offers/persist'
import {
  availablePlanPersist,
  selectedPlanInfoPersist,
  selectedPlanPersist,
  selectedSlotPersist,
  slotBookedPersist,
  paymentDonePersist,
  studiosPersist,
  selectedStudioPersist,
  isMoreThanOneStudioPersist,
  slotNotFoundPersist,
} from 'redux/persist'
import referralReducer from 'redux/Referral/nonPersist'
import referralPersistReducer from 'redux/Referral/persist'
import regularReducer from 'redux/Regular'
import waiverReducer from 'redux/waiver'

const persistConfig = {
  key: 'root',
  storage,
  // Only allow persistence for reducer
  whitelist: [
    'availablePlan',
    'selectedPlan',
    'selectedSlot',
    'selectedPlanInfo',
    'slotBooked',
    'slotNotFound',
    'studios',
    'selectedStudio',
    'isMoreThanOneStudio',
    'studiosOffers',
    'selectedStudioOffers',
    'isMoreThanOneStudioOffers',
    'payment',
    'selectedSlotOffer',
    'paymentPlansOffer',
    'purchasedPlanOffer',
    'paymentDoneOffer',
    'slotBookedOfferFlow',
    'slotNotFoundOfferFlow',
    'visitCountOfferFlow',
    'timeLeftOfferFlow',
    'timeLeftHomeOfferFlow',
    'booking',
    'referralPersist',
  ],
}

const rootReducer = combineReducers({
  auth: authReducer,
  regular: regularReducer,
  referral: referralReducer,
  offers: offersReducer,
  bookingNonPersist: bookingNonPersistReducer,
  //regularPersist
  availablePlan: availablePlanPersist,
  selectedPlan: selectedPlanPersist,
  selectedSlot: selectedSlotPersist,
  selectedPlanInfo: selectedPlanInfoPersist,
  slotBooked: slotBookedPersist,
  slotNotFound: slotNotFoundPersist,
  studios: studiosPersist,
  selectedStudio: selectedStudioPersist,
  isMoreThanOneStudio: isMoreThanOneStudioPersist,
  waiver: waiverReducer,
  payment: paymentDonePersist,
  // offersFlow
  selectedSlotOffer: selectedSlotOfferFlowPersist,
  paymentPlansOffer: paymentPlanOfferFlowPersist,
  purchasedPlanOffer: purchasedPlanOfferFlowPersist,
  paymentDoneOffer: paymentDoneOfferFlowPersist,
  slotBookedOfferFlow: slotBookedOfferFlowPersist,
  slotNotFoundOfferFlow: slotNotFoundOfferFlowPersist,
  visitCountOfferFlow: visitCountOfferFlowPersist,
  timeLeftOfferFlow: timeLeftOfferFlowPersist,
  timeLeftHomeOfferFlow: timeLeftHomeOfferFlowPersist,
  studiosOffers: studiosOffersPersist,
  selectedStudioOffers: selectedStudioOffersPersist,
  isMoreThanOneStudioOffers: isMoreThanOneStudioOffersPersist,
  // referralFlow
  referralPersist: referralPersistReducer,
  //bookingFlow
  booking: bookingReducer,
})

export type RootState = ReturnType<typeof rootReducer>

const persistedRootReducer = persistReducer(persistConfig, rootReducer)
const store = configureStore({
  reducer: persistedRootReducer,
  devTools: true,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})

export type AppDispatch = typeof store.dispatch

export const persister = persistStore(store)
export default store

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
