import { useEffect, useState } from 'react'
import { Button, Dialog, DialogTitle } from '@mui/material'
import axios from 'axios'
import { format } from 'date-fns'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { AppConstants, Channel, LeadStage } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { SetSelectedSlot, SetSlotBookedAlready, SetSlotNotFound } from 'redux/persist'
import { useAppDispatch, useAppSelector } from 'redux/store'
import styles from './ConfirmAppointment.module.scss'

const ConfirmAppointment = () => {
  const styless: any = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //   position: 'absolute' as 'absolute',
    //   top: '44%',
    //   left: '47.5%',
    //   transform: 'translate(-50%, -50%)',
    width: 300,
    height: 170,
    overflow: 'hidden',
    backgroundColor: '#050624',
    color: 'white',
    borderRadius: '8px',
    p: 4,
  }
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const appointmentTime = useAppSelector((state) => state.selectedSlot.selectedSlot)
  const slotBookedAlready = useAppSelector((state) => state.slotBooked.slotBookedAlready)
  const [error, setError] = useState(false)
  const [slotNotFoundError, setSlotNotFoundError] = useState(false)
  const paymentDone = useAppSelector((state) => state.payment.paymentDone)
  const studios = useAppSelector((state) => state.studios.studios)
  const selectedStudio = useAppSelector((state) => state.selectedStudio.studio)
  const isMoreThanOneStudio = useAppSelector((state) => state.isMoreThanOneStudio.isMoreThanOneStudio)
  const selectedPlanInfo = useAppSelector((state) => state.selectedPlanInfo.selectedPlanInfo)

  const schedulePhysicalVisit = async () => {
    if (appointmentTime?.scheduleObject) {
      const response: any = await axios
        .post(AppConstants.API_URL + '/visit/chiropractor/appointment', appointmentTime.scheduleObject, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })

      if (response?.data?.code === 'success') {
        navigate('/welcome')
      } else {
        if (response?.data?.code === 'slot_booked_already') {
          setError(true)
          dispatch(SetSlotBookedAlready(false))
          dispatch(SetSelectedSlot(null))
        } else if (response?.data?.code === 'slot_not_found') {
          setSlotNotFoundError(true)
          dispatch(SetSlotNotFound(false))
          dispatch(SetSelectedSlot(null))
        } else {
        }

        return false
      }
    } else {
      return false
    }
  }

  const postLeadStage = async () => {
    await axios
      .post(
        AppConstants.API_URL + '/lead/stage/update',
        { stage: LeadStage.confirmation, channel: Channel.direct },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        },
      )
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err?.response
      })
  }

  useEffect(() => {
    postLeadStage()
  }, [])

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'confirm-visit',
      page_location: '/confirm-visit',
    })
  }, [])

  return (
    <>
      <div className={styles.container}>
        <Header
          headerText=""
          showBackButton={true}
          onBack={() => {
            navigate('/choose-plan')
          }}
        ></Header>
        <div className={styles.locationContainer}>
          <div className={styles.confirmHeader}>Confirm your appointment.</div>
          {studios && (
            <div className={styles.locationDetails}>
              <div className={styles.locationTextContainer}>
                <div className={styles.locationWrapper}>
                  <div className={styles.appointmentTime}>
                    {appointmentTime
                      ? format(new Date(appointmentTime?.scheduleObject.appointmentTime), 'EEEE, MMM dd')
                      : ''}
                    <div>
                      {appointmentTime
                        ? format(new Date(appointmentTime?.scheduleObject.appointmentTime), 'h:mm a')
                        : ''}
                    </div>
                  </div>
                  <div>
                    <div className={styles.locationText} style={{ fontWeight: 700 }}>
                      {isMoreThanOneStudio ? selectedStudio?.name : studios[0]?.name}
                    </div>
                    <div className={styles.locationText}>
                      {isMoreThanOneStudio ? selectedStudio?.address : studios[0]?.address}
                    </div>
                    {isMoreThanOneStudio ? (
                      <div className={styles.cityAndState}>
                        <div style={{ marginRight: '4px' }}>{selectedStudio?.city.name + ','}</div>
                        <div style={{ marginRight: '4px', textTransform: 'uppercase' }}>{selectedStudio?.state}</div>
                        <div>{selectedStudio?.zipCode}</div>
                      </div>
                    ) : (
                      <div className={styles.cityAndState}>
                        <div style={{ marginRight: '4px' }}>{studios[0]?.city.name + ','}</div>
                        <div style={{ marginRight: '4px', textTransform: 'uppercase' }}>{studios[0]?.state}</div>
                        <div>{studios[0]?.zipCode}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <img
                style={{ width: '145px', height: '150px', cursor: 'pointer', objectFit: 'cover' }}
                className={styles.mapImageItem}
                src={isMoreThanOneStudio ? selectedStudio?.mapImage || '' : studios[0]?.mapImage || ''}
                alt="map"
                onClick={() => {
                  if (studios && selectedStudio && selectedStudio?.mapLocation && isMoreThanOneStudio) {
                    window.open(selectedStudio.mapLocation, '_blank')
                  } else if (studios && studios.length === 1 && studios[0]?.mapLocation) {
                    window.open(studios[0].mapLocation, '_blank')
                  }
                  //   window.location.href =
                  //     'https://www.google.com/maps/place/302+Mott+St,+New+York,+NY+10012,+USA/@40.7248592,-73.9940347'
                }}
              />
            </div>
          )}

          {error && (
            <Dialog
              onClose={() => {
                // setError(false)
              }}
              open={error}
              PaperProps={{ style: styless }}
              className={styles.modalPopUp}
            >
              <DialogTitle style={{ fontSize: '14px', marginTop: '0rem' }}>
                We’re sorry, the slot you picked has been booked already. Please select another slot.
              </DialogTitle>
              <Button
                color="primary"
                style={{
                  width: 'fit-content',
                  marginBottom: '1rem',
                  height: '2rem',
                  padding: '0px 8px 0px 8px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  setError(false)
                  navigate('/physical-visit')
                }}
              >
                Reschedule
              </Button>
            </Dialog>
          )}
          {slotNotFoundError && (
            <Dialog
              onClose={() => {
                // setError(false)
              }}
              open={slotNotFoundError}
              PaperProps={{ style: styless }}
              className={styles.modalPopUp}
            >
              <DialogTitle style={{ fontSize: '14px', marginTop: '0rem' }}>
                We’re sorry, time exceeded to book the slot. Please select another slot.
              </DialogTitle>
              <Button
                color="primary"
                style={{
                  width: 'fit-content',
                  marginBottom: '1rem',
                  height: '2rem',
                  padding: '0px 8px 0px 8px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  setSlotNotFoundError(false)
                  navigate('/physical-visit')
                }}
              >
                Reschedule
              </Button>
            </Dialog>
          )}
        </div>
        {/* <div className={styles.cancellationPolicy}>
          <div className={styles.policyHeader}>Cancellation Policy</div>
          <div className={styles.cancellation}>
            Appointments canceled or rescheduled at least 12 hours in advance of the appointment time will not incur a
            penalty. Cancellations made between 12 hours and 1 hour in advance of the appointment time will result in a
            $10 penalty. If canceled less than 1 hour in advance of the appointment time or in the case of a no-show, a
            $20 penalty will apply. This policy ensures that our doctors can offer the slot to other patients in need of
            care.
          </div>
        </div> */}
        <Footer
          buttonText={
            paymentDone ? 'Confirm' : `Confirm and Pay $${selectedPlanInfo && selectedPlanInfo?.amount / 100}`
          }
          isLoading={false}
          trackerIndex={isMoreThanOneStudio ? 6 : 5}
          shouldShowFour={true}
          shouldShowFive={true}
          shouldShowSix={true}
          shouldShowSeven={isMoreThanOneStudio}
          isDisabled={false}
          buttonType={'button'}
          onButtonClick={() => {
            if (!paymentDone && appointmentTime?.scheduleObject) {
              navigate('/payment')
            } else {
              schedulePhysicalVisit()
            }
          }}
        ></Footer>
      </div>
    </>
  )
}

export default ConfirmAppointment
