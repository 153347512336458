import React, { useState, useEffect, useMemo } from 'react'
import { KeyboardArrowDown } from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  DialogTitle,
  Grid,
  MenuItem,
  Modal,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import axios from 'axios'
import format from 'date-fns/format'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { AppConstants, Channel, LeadStage } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
import HorizontalCalendar from 'components/shared/HorizontalCalendar'
import { getScreeningAvailabilityData, resetLogin } from 'redux/auth'
import useAuth from 'redux/auth/useAuth'
import { SetSelectedSlot, SetSelectedStudio, SetSlotBookedAlready, SetSlotNotFound } from 'redux/persist'
import { useAppDispatch, useAppSelector } from 'redux/store'
import styles from './Onboard.module.scss'

export interface ITime {
  index: number
  value: string
}
interface IScheduleInitialValues {
  appointmentTime: string
  timezone: string
}
const scheduleInitialValues: IScheduleInitialValues = {
  appointmentTime: '',
  timezone: 'America/New_York',
}

const styless: any = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  //   position: 'absolute' as 'absolute',
  //   top: '44%',
  //   left: '47.5%',
  //   transform: 'translate(-50%, -50%)',
  width: 300,
  height: 170,
  overflow: 'hidden',
  backgroundColor: '#050624',
  color: 'white',
  borderRadius: '8px',
  p: 4,
}

const PhysicalVisit = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const studios = useAppSelector((state) => state.studios.studios)
  const selectedStudio = useAppSelector((state) => state.selectedStudio.studio)
  const isMoreThanOneStudio = useAppSelector((state) => state.isMoreThanOneStudio.isMoreThanOneStudio)

  const selectedSlot = useAppSelector((state) => state.selectedSlot.selectedSlot)
  const [disableButton, setDisableButton] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const [loadingContanier, setLoadingContainer] = useState(true)
  const [visitAvailabilities, setVisitAvailabilities] = useState([])
  const [selectedDate, setSelectedDate] = useState(selectedSlot ? new Date(selectedSlot?.date) : new Date())
  const [selectedTime, setSelectedTime] = useState<any | null>(selectedSlot ? selectedSlot?.value : null)
  const [selectedIndex, setSelectedIndex] = useState<any | null>(selectedSlot ? selectedSlot?.index : null)
  const [scheduleData, setscheduleData] = useState({
    appointmentTime: selectedSlot?.scheduleObject?.appointmentTime || '',
    timezone: 'America/New_York',
    clinicId: isMoreThanOneStudio ? selectedStudio?.id : studios[0]?.id,
  })
  const [deselect, setDeSelect] = useState(false)
  const [apiFailed, setApiFailed] = useState(false)
  const [showDialogue, setShowDialogue] = useState(true)
  const paymentDone = useAppSelector((state) => state.payment.paymentDone)
  const slotBookedAlready = useAppSelector((state) => state.slotBooked.slotBookedAlready)
  const slotNotFound = useAppSelector((state) => state.slotNotFound.slotNotFound)
  const availabileSlots: any = visitAvailabilities
  const [chosenStudio, setChosenStudio] = useState({ id: '', name: '' })

  const timeDetails: any = useMemo(() => {
    return (
      availabileSlots?.map((timeString: any) => {
        const formattedTime = format(new Date(`2000-01-01T${timeString}`), 'HH:mm')
        return formattedTime
      }) || []
    )
  }, [availabileSlots])

  const formattedTimes: string[] =
    timeDetails?.map((timeString: any) => {
      const convertedTime = format(new Date(`2000-01-01T${timeString}`), 'h:mm aa')
      return convertedTime
    }) || []

  const handleSelect = async (value: any, index: any) => {
    setSelectedTime(value)
    setSelectedIndex(index)
    setDisableButton(false)

    let scheduleTime = timeDetails[index]

    let selectedAppointmentTime = String(
      selectedDate.getFullYear() +
        '-' +
        String(selectedDate.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(selectedDate.getDate()).padStart(2, '0') +
        ' ' +
        scheduleTime,
    )
    setscheduleData({
      ...scheduleData,
      appointmentTime: selectedAppointmentTime,
      clinicId: isMoreThanOneStudio ? selectedStudio?.id : studios[0]?.id,
    })
  }

  // const schedulePhysicalVisit = async () => {
  //   if (scheduleData.appointmentTime) {
  //     setLoadingButton(true)
  //     const response: any = await axios
  //       .post(AppConstants.API_URL + '/visit/chiropractor/appointment', scheduleData, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem('userToken')}`,
  //           'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
  //         },
  //       })
  //       .then((res) => {
  //         return res
  //       })
  //       .catch((err) => {
  //         return err.response
  //       })

  //     if (response?.data?.code === 'success') {
  //       navigate('/welcome')
  //     } else {
  //       if (response?.data?.code === 'slot_booked_already') {
  //         setError(true)
  //         setSelectedIndex(null)
  //         setSelectedTime(null)
  //         setscheduleData({ ...scheduleData, appointmentTime: '' })
  //         setDisableButton(true)
  //         setLoadingButton(false)
  //       } else {
  //         setSelectedIndex(null)
  //         setSelectedTime(null)
  //         setscheduleData({ ...scheduleData, appointmentTime: '' })
  //         setDisableButton(true)
  //         setLoadingButton(false)
  //       }

  //       return false
  //     }
  //   } else {
  //     return false
  //   }
  // }

  const formattedDate =
    selectedDate.getFullYear() +
    '-' +
    String(selectedDate.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(selectedDate.getDate()).padStart(2, '0') +
    ' ' +
    '00:00:00'

  useEffect(() => {
    getClinicAvailability(formattedDate, isMoreThanOneStudio ? selectedStudio?.id : studios[0]?.id)
  }, [])

  useEffect(() => {
    if (isMoreThanOneStudio) {
      if (selectedStudio) setChosenStudio({ id: selectedStudio?.id, name: selectedStudio?.name })
    }
  }, [])

  useEffect(() => {
    if (selectedSlot) {
      let isSlotSelected = formattedTimes?.includes(
        format(new Date(selectedSlot?.scheduleObject.appointmentTime), 'h:mm aa'),
      )

      if (isSlotSelected && !deselect) {
        setDisableButton(false)
      }
    }
  }, [formattedTimes])

  const handleSelectedDate = () => {
    dispatch(SetSelectedSlot(null))
    setSelectedIndex(null)
    setSelectedTime(null)
    setscheduleData({
      ...scheduleData,
      appointmentTime: '',
      clinicId: isMoreThanOneStudio ? selectedStudio?.id : studios[0]?.id,
    })
    setDisableButton(true)
    setSelectedDate((prevSelectedDate: any) => {
      const formattedDate =
        prevSelectedDate.getFullYear() +
        '-' +
        String(prevSelectedDate.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(prevSelectedDate.getDate()).padStart(2, '0') +
        ' ' +
        '00:00:00'
      if (isMoreThanOneStudio) {
        getClinicAvailability(formattedDate, chosenStudio.id)
      } else {
        getClinicAvailability(formattedDate, studios[0]?.id)
      }
      return prevSelectedDate
    })
  }

  // async function getDoctorAvailability(formattedDate: any, clinicId: any) {
  //   setLoadingContainer(true)
  //   setApiFailed(false)
  //   // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  //   const timeZone = 'America/New_York'
  //   try {
  //     let response = await axios
  //       .get(
  //         AppConstants.API_URL +
  //           `/visit/clinic/availability?timezone=${timeZone}&clinicId=${
  //             isMoreThanOneStudio ? selectedStudio?.id : studios[0]?.id
  //           }&date=${formattedDate}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem('userToken')}`,
  //             'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
  //           },
  //         },
  //       )
  //       .then((res) => {
  //         setLoadingContainer(false)
  //         if (res?.data?.data.length === 0) {
  //           setVisitAvailabilities(res?.data?.data)
  //         } else if (res?.data?.data?.length > 0) {
  //           let filteredData: any = []
  //           let seenHours: any = {}
  //           res?.data?.data.forEach((time: any) => {
  //             const hour = time.split(':')[0]
  //             if (!seenHours[hour]) {
  //               filteredData.push(time)
  //               seenHours[hour] = true
  //             }
  //           })
  //           setVisitAvailabilities(filteredData)
  //         }
  //       })
  //       .catch((err) => {
  //         if (err) {
  //           setApiFailed(true)
  //           setLoadingContainer(false)
  //         }
  //       })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  async function getClinicAvailability(formattedDate: any, clinicId: any) {
    setLoadingContainer(true)
    setApiFailed(false)
    // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const timeZone = 'America/New_York'
    try {
      let response = await axios
        .get(
          AppConstants.API_URL +
            `/visit/clinic/availability?timezone=${timeZone}&clinicId=${clinicId}&date=${formattedDate}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
            },
          },
        )
        .then((res) => {
          setLoadingContainer(false)
          if (res?.data?.data.length === 0) {
            setVisitAvailabilities(res?.data?.data)
          } else if (res?.data?.data?.length > 0) {
            let filteredData: any = []
            let seenHours: any = {}
            res?.data?.data.forEach((time: any) => {
              const hour = time.split(':')[0]
              if (!seenHours[hour]) {
                filteredData.push(time)
                seenHours[hour] = true
              }
            })
            setVisitAvailabilities(filteredData)
          }
        })
        .catch((err) => {
          if (err) {
            setApiFailed(true)
            setLoadingContainer(false)
            setVisitAvailabilities([])
          }
        })
    } catch (error) {
      console.log(error)
      if (error) {
        setApiFailed(true)
        setLoadingContainer(false)
        setVisitAvailabilities([])
      }
    }
  }

  const postLeadStage = async () => {
    await axios
      .post(
        AppConstants.API_URL + '/lead/stage/update',
        { stage: LeadStage.slot, channel: Channel.direct },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        },
      )
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err?.response
      })
  }

  useEffect(() => {
    postLeadStage()
  }, [])

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'physical-visit',
      page_location: '/physical-visit',
    })
  }, [])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <span style={{ flex: 1, marginLeft: '10px', fontSize: 20 }}>{'Pick a slot.'}</span>
          {/* {showClinicAddress && <span className={style.clinicAddress}>{clinicAddress}</span>} */}
          {isMoreThanOneStudio && (
            <Select
              IconComponent={KeyboardArrowDown}
              value={chosenStudio.name}
              onChange={(e) => {
                if (studios) {
                  const pickedStudio = studios?.find((studio) => studio.name === e.target.value)
                  if (pickedStudio) {
                    setChosenStudio({ id: pickedStudio.id, name: pickedStudio.name })
                    dispatch(SetSelectedStudio(pickedStudio))
                    getClinicAvailability(formattedDate, pickedStudio.id)
                  }
                  dispatch(SetSelectedSlot(null))
                  setSelectedIndex(null)
                  setSelectedTime(null)
                  setscheduleData({
                    ...scheduleData,
                    appointmentTime: '',
                    clinicId: isMoreThanOneStudio ? pickedStudio?.id : studios[0]?.id,
                  })
                  setDisableButton(true)
                }
              }}
              displayEmpty
              sx={{
                width: '164px',
                '& .MuiSelect-select': {
                  fontSize: '14px',
                  '@media (max-width: 700px)': {
                    fontSize: '13px',
                  },
                },
                '@media (max-width: 700px)': {
                  width: '154px',
                },

                height: '31px',
                marginTop: '10px',
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root': {
                      '@media (max-width: 600px)': {
                        minHeight: '33px',
                      },
                      fontSize: '14px',
                    },
                    '& .MuiMenuItem-root.Mui-selected': {
                      backgroundColor: '#2A2D56 !important',
                    },
                    '& .MuiMenuItem-root.Mui-selected:hover': {
                      backgroundColor: '#2A2D56 !important',
                    },
                  },
                },
              }}
            >
              {[...studios]
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((studio) => (
                  <MenuItem key={studio.id} value={studio.name}>
                    {studio.name}
                  </MenuItem>
                ))}
            </Select>
          )}
          <img src={'/logo2.png'} alt="logo" className={styles.logo} />
        </div>
        {/* <Header
          headerText={'Pick a slot.'}
          showBackButton={false}
          onBack={() => {
            if (isMoreThanOneStudio) {
              navigate('/select-studio')
            } else {
              navigate('/choose-plan')
            }
          }}
          clinicAddress={isMoreThanOneStudio ? selectedStudio?.name : studios[0]?.name}
          showClinicAddress={isMoreThanOneStudio ? true : false}
          styles={{ fontSize: '20px' }}
        ></Header> */}
        <div className={`${styles.scheduleDetails}`}>
          <div>
            <HorizontalCalendar
              onClick={() => {
                handleSelectedDate()
              }}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />

            <div className={styles.toDayDate}>{format(new Date(selectedDate), 'EEEE, MMMM d')}</div>
            {/* <div className={styles.clinicAddress}>
              <img src="/images/locationIcon.svg" alt="pin"></img>
              <span>{isMoreThanOneStudio ? selectedStudio?.address : studios[0]?.address}</span>
            </div> */}
            <div className={styles.slotContainer}>
              {loadingContanier === true && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <CircularProgress style={{ color: '#007f7c' }} size={30}></CircularProgress>
                </div>
              )}
              {formattedTimes.length !== 0 && loadingContanier === false && (
                <Grid columns={1} container spacing={2}>
                  {formattedTimes?.map((value: any, index) => {
                    return (
                      <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        item
                        xs={6}
                        sm={6}
                        key={index}
                      >
                        <Button
                          className={styles.slotButton}
                          onClick={() => {
                            handleSelect(value, index)
                            if (selectedTime === value) {
                              setSelectedTime(null)
                              setSelectedIndex(null)
                              setDeSelect(true)
                              setscheduleData({
                                ...scheduleData,
                                appointmentTime: '',
                                timezone: 'America/New_York',
                                clinicId: isMoreThanOneStudio ? selectedStudio?.id : studios[0]?.id,
                              })
                              setDisableButton(true)
                            }
                          }}
                          sx={{
                            backgroundColor: selectedTime === value ? '#F5F5F5' : '#2A2D56',
                            border: selectedTime === value ? ' 1px solid #007F7C' : '1px solid #2A2D56',
                            // '@media (min-width: 600px)': {
                            //   width: '140px',
                            //   height: '46px',
                            //   fontSize: '14px',
                            // },
                            // '@media (max-width: 375px)': {
                            //   width: '150px',
                            //   height: '46px',
                            //   fontSize: '14px',
                            // },
                            // '@media (max-width: 350px)': {
                            //   width: '138px',
                            //   height: '46px',
                            //   fontSize: '14px',
                            // },
                            // '@media (max-width: 330px)': {
                            //   width: '130px',
                            //   height: '46px',
                            //   fontSize: '14px',
                            // },
                            // '@media (max-width: 310px)': {
                            //   width: '120px',
                            //   height: '46px',
                            //   fontSize: '14px',
                            // },
                            // '@media (max-width: 300px)': {
                            //   width: '110px',
                            //   height: '46px',
                            //   fontSize: '14px',
                            // },
                            color: selectedTime === value ? '#050624' : '#E5E7EB',
                            fontSize: '16px',
                            boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.20)',
                            '&:hover':
                              selectedTime === value ? { backgroundColor: '#F5F5F5' } : { backgroundColor: '#2A2D56' },
                          }}
                        >
                          {value}
                        </Button>
                      </Grid>
                    )
                  })}
                </Grid>
              )}
              {loadingContanier === false && !apiFailed && formattedTimes.length === 0 && (
                <div className={styles.noSlotDiv}>
                  <div>
                    <img src={'images/noSlots.svg'} alt="no slots"></img>
                  </div>
                  <div className={styles.noSlots}>No slots available</div>
                  <div className={styles.noSlotsText}>Please try a different date or</div>
                  <div className={styles.noSlotsText} style={{ marginTop: '2px' }}>
                    check other location
                  </div>
                </div>
              )}
              {loadingContanier === false && apiFailed && formattedTimes.length === 0 && (
                <div className={styles.noSlotDiv}>
                  <div>
                    <img src={'images/noSlots.svg'} alt="no slots"></img>
                  </div>
                  <div className={styles.noSlots}>Something went wrong</div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.btnWrapper}>
            <Grid item xs={12}>
              {slotBookedAlready && showDialogue && (
                <Dialog
                  onClose={() => {
                    // setShowDialogue(false)
                  }}
                  open={slotBookedAlready && showDialogue}
                  PaperProps={{ style: styless }}
                  className={styles.modalPopUp}
                >
                  <DialogTitle style={{ fontSize: '14px', marginTop: '0rem' }}>
                    Your payment has been processed successfully. Unfortunately, the slot you selected is no longer
                    available. Please choose another available slot.
                  </DialogTitle>
                  <Button
                    color="primary"
                    style={{
                      width: 'fit-content',
                      marginBottom: '1rem',
                      height: '2rem',
                      padding: '0px 8px 0px 8px',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      setShowDialogue(false)
                      dispatch(SetSlotBookedAlready(false))
                    }}
                  >
                    Reschedule
                  </Button>
                </Dialog>
              )}
              {slotNotFound && showDialogue && (
                <Dialog
                  onClose={() => {
                    // setShowDialogue(false)
                  }}
                  open={slotNotFound && showDialogue}
                  PaperProps={{ style: styless }}
                  className={styles.modalPopUp}
                >
                  <DialogTitle style={{ fontSize: '14px', marginTop: '0rem' }}>
                    Your payment has been processed successfully. Unfortunately, the slot you selected is no longer
                    available. Please choose another available slot.
                  </DialogTitle>
                  <Button
                    color="primary"
                    style={{
                      width: 'fit-content',
                      marginBottom: '1rem',
                      height: '2rem',
                      padding: '0px 8px 0px 8px',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      setShowDialogue(false)
                      dispatch(SetSlotNotFound(false))
                    }}
                  >
                    Reschedule
                  </Button>
                </Dialog>
              )}
              {/* {error && (
                <Dialog
                  onClose={() => {
                    // setError(false)
                  }}
                  open={error}
                  PaperProps={{ style: styless }}
                  className={styles.modalPopUp}
                >
                  <DialogTitle style={{ fontSize: '14px', marginTop: '0rem' }}>
                    We’re sorry, the slot you picked has been booked already. Please select another slot.
                  </DialogTitle>
                  <Button
                    color="primary"
                    style={{
                      width: 'fit-content',
                      marginBottom: '1rem',
                      height: '2rem',
                      padding: '0px 8px 0px 8px',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      getDoctorAvailability(formattedDate)
                      setError(false)
                    }}
                  >
                    Reschedule
                  </Button>
                </Dialog>
              )} */}
            </Grid>
          </div>
        </div>
        <Footer
          buttonText={'Continue'}
          isLoading={loadingButton}
          trackerIndex={isMoreThanOneStudio ? 4 : 3}
          shouldShowFour={true}
          shouldShowFive={true}
          shouldShowSix={true}
          shouldShowSeven={isMoreThanOneStudio}
          isDisabled={disableButton}
          buttonType={'button'}
          onButtonClick={() => {
            if (!disableButton && !loadingButton) {
              if (scheduleData) {
                dispatch(
                  SetSelectedSlot({
                    scheduleObject: scheduleData,
                    index: selectedIndex,
                    value: selectedTime,
                    date: selectedDate,
                  }),
                )
                if (!paymentDone) {
                  navigate('/choose-plan')
                } else {
                  navigate('/confirm-visit')
                }
              }
            }
          }}
        ></Footer>
      </div>
    </>
  )
}

export default PhysicalVisit
